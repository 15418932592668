<template>
  <div>
    <div class="text-center pt-2 pb-2" v-if="loadingBlueprints">
      <a-spin />
    </div>
    <template v-else>
      <a-select
        allowClear
        show-search
        @change="selectedBlueprint"
        v-model:value="selectedBlueprintValue"
        style="width: 250px"
        placeholder="Select Business Objects"
        v-if="selectedBlueprintId && blueprints?.length"
        :options="selectOptions"
        :filter-option="filterOption"
      >
      </a-select>
      <a-divider />
      <template v-for="copyBlueprint in copyFromBlueprint" :key="copyBlueprint._id">
        <a-card size="small" style="margin-bottom: 10px">
          <template #title>
            <span>{{ capitalize(copyBlueprint.name) }}.</span>
            <template v-if="selectedBlueprintId && blueprints?.length && instancesFromBlueprint.length > 1">
              <span style="margin-left: 30%"> Instances from: </span>
              <a-select
                allowClear
                show-search
                @change="value => setInstancesBlueprintId(copyBlueprint._id, value)"
                :default-value="getInstancesBlueprintId(copyBlueprint._id)"
                style="width: 250px"
                placeholder="Select Business Objects"
                :options="instancesFromBlueprint"
                :filter-option="filterOption"
              >
              </a-select>
            </template>
          </template>
          <template #extra>
            <a @click="removeOne(copyBlueprint._id)">Delete</a>
          </template>
          <div class="mb-3">
            <a-checkbox
              :checked="getCheckedAddOnlyInstanceNotExist(copyBlueprint._id)"
              @change="e => setCheckedAddOnlyInstanceNotExist(copyBlueprint._id, e)"
            >
              Add only if the instance does not exist
              <a-tooltip>
                <template #title>Verify if an instance exists based on the fields, and add it only if the instance does not already exist.</template>
                <InfoCircleOutlined />
              </a-tooltip>
            </a-checkbox>
            <span class="mr-2">Search fields:</span>
            <a-select
              getAddOnlyInstanceNotExistSearchFields
              @change="value => setAddOnlyInstanceNotExistSearchFields(copyBlueprint._id, value)"
              :default-value="getAddOnlyInstanceNotExistSearchFields(copyBlueprint._id)"
              :max-tag-count="2"
              allowClear
              show-search
              style="width: 350px"
              placeholder="Select fields..."
              mode="multiple"
              :filter-option="filterOption"
            >
              <a-select-option v-for="field in copyBlueprint.fields" :key="field._id" :value="field._id">
                {{ field.label }}
              </a-select-option>
            </a-select>

            <a-checkbox :checked="getUpdateFindInstance(copyBlueprint._id)" @change="e => setUpdateFindInstance(copyBlueprint._id, e)" class="ml-3">
              Update find instance
              <a-tooltip>
                <template #title>Update find instance</template>
                <InfoCircleOutlined />
              </a-tooltip>
            </a-checkbox>
          </div>
          <div class="update-fields" v-for="field in copyBlueprint.fields" :key="field._id" style="margin-bottom: 10px">
            <div class="cell input-name">
              <strong>{{ capitalize(field.label) }}</strong>
              <div class="muted">{{ field.structure.type }}</div>
            </div>

            <div class="cell modify-filed" style="text-align: center">
              <a-radio-group
                :value="selectBindType(copyBlueprint._id, field._id)"
                @change="changeBindType(copyBlueprint._id, field._id, field.structure.type, $event)"
                button-style="solid"
              >
                <a-radio-button value="from-field">Insert value from</a-radio-button>
                <a-radio-button value="static-value">Static value</a-radio-button>
              </a-radio-group>
            </div>

            <template v-if="selectBindType(copyBlueprint._id, field._id) === 'static-value'">
              <a-input
                :default-value="staticValue(copyBlueprint._id, field._id)"
                @change="changeStaticValue(copyBlueprint._id, field._id, $event)"
                style="margin-top: 5px"
                placeholder="Value"
              />
            </template>

            <template v-else>
              <template v-if="getInstancesBlueprintId(copyBlueprint._id) != selectedBlueprintId">
                Data from:
                <a-radio-group
                  :value="getDataFromBlueprint(copyBlueprint._id, field._id)"
                  @change="setDataFromBlueprint(copyBlueprint._id, field._id, $event, index)"
                  option-type="button"
                  :options="
                    instancesFromBlueprint.filter(
                      item => item.disabled !== true && (item.main || item.value === getInstancesBlueprintId(copyBlueprint._id))
                    )
                  "
                  size="small"
                  button-style="solid"
                >
                </a-radio-group>
                <br />
              </template>
              <template v-if="!fromPlugin(copyBlueprint._id, field._id)">
                <a-checkbox
                  :checked="getUseHtml(copyBlueprint._id, field._id)"
                  @change="event => setUseHtml(copyBlueprint._id, field._id, event)"
                  style="margin-top: 5px; margin-bottom: 5px"
                  >Use advanced html</a-checkbox
                >
                <div v-if="!getUseHtml(copyBlueprint._id, field._id)">
                  <a-button-group class="mt-1" v-if="showRefFieldPath(copyBlueprint._id, field._id) !== ''">
                    <a-button style="cursor: default" type="primary">
                      {{ showRefFieldPath(copyBlueprint._id, field._id) }}
                    </a-button>
                    <a-button @click="editBindField(copyBlueprint._id, field._id)"><EditOutlined /> </a-button>
                  </a-button-group>
                  <div
                    class="mt-1"
                    v-if="
                      (bindField[copyBlueprint._id] ?? '') === field._id ||
                      (showRefFieldPath(copyBlueprint._id, field._id) === '' && (bindField[copyBlueprint._id] ?? '') !== field._id)
                    "
                  >
                    <ReferenceFields
                      v-if="blueprint && resetReferenceField(field._id) === field._id"
                      :blueprints="blueprints"
                      :defaultSettings="{
                        optionChangedData: 'CHANGE DATA (history)',
                      }"
                      :selectedBlueprint="getBlueprintWithFields(getDataFromBlueprintForReference(copyBlueprint._id, field._id))"
                      :toFieldFromOtherBlueprint="false"
                      :justReferenceFields="false"
                      :justSingleReferenceFields="true"
                      :mainBlueprint="null"
                      :cardView="false"
                      :otherBlueprint="false"
                      @toField="(value, index) => selectField(copyBlueprint._id, field._id, field.structure.type, value, index)"
                    />
                  </div>
                </div>

                <div v-if="getUseHtml(copyBlueprint._id, field._id)" class="pb-3">
                  <dot-js-html
                    :initial-value="fromHtml(copyBlueprint._id, field._id)"
                    :blueprint-id-list="getBpIdListTokens(field._id, copyBlueprint._id)"
                    :blueprints="blueprints"
                    @update-value="value => setFromHtml(copyBlueprint._id, field._id, value)"
                  />
                </div>
              </template>
              <template v-else>
                <span>Plugin fields:</span><br />
                <a-select
                  allowClear
                  show-search
                  @change="value => setPluginField(copyBlueprint._id, field._id, field.structure.type, value)"
                  :default-value="getPluginField(copyBlueprint._id, field._id)"
                  style="width: 250px"
                  placeholder="Select plugin fields"
                  :options="pluginExpectedFields(getInstancesBlueprintId(copyBlueprint._id))"
                  :filter-option="filterOption"
                >
                </a-select>
              </template>
            </template>
          </div>
        </a-card>
      </template>
    </template>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFields.vue';
import _ from 'lodash';
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons-vue';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { slugify } from '@/core/utils/string-manipulation';
import { message } from 'ant-design-vue';
import dotJsHtml from '@/apps/workflow/views/WorkflowBuilder/components/events/common/dotJsHtml.vue';
import { pluginsManagementApi } from '@/apps/pluginsManagement/api';

export default {
  name: 'workflowBuilderCopyDataIndex',
  components: {
    InfoCircleOutlined,
    // CopyOutlined,
    // FieldWidget,
    ReferenceFields,
    EditOutlined,
    dotJsHtml,
  },
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList', 'eventIndex'],
  async mounted() {
    this.extensions = [javascript(), oneDark];
    await this.fetchPlugins();
    await this.fetchAllBlueprint();
    //
    await Promise.all(
      this.localEvent.copyData.map(async data => {
        const blueprint = this.blueprints.find(b => b._id.toString() === data?.blueprintId?.toString());
        if (blueprint && !this.copyFromBlueprint.some(item => item.blueprint._id === blueprint._id)) {
          this.copyFromBlueprint.push({ ...blueprint });
          if (!data.instancesFromBlueprintId) {
            data.instancesFromBlueprintId = blueprint._id;
          }
        }
      })
    );
    //
    await this.fetchBlueprint();
    this.findMainBlueprintReferenceFields();
  },
  data() {
    return {
      fields: [],
      blueprint: null,
      selectedBlueprintIdValue: null,
      copyFromBlueprint: [],
      blueprints: [],
      mainBlueprintReferenceFields: {},
      bindField: {},
      referenceFieldsKey: 1,
      localEvent: { ...this.event },
      extensions: null,
      tokenModalVisible: false,
      loadingBlueprints: false,

      selectedBlueprintValue: undefined,
      pluginData: {},

      tokenModal: {
        visible: false,
        blueprintId: null,
        toFieldId: null,
        lastField: null,
        modalName: '',
        instancesBlueprintId: null,
      },
      resetId: null,
      plugins: null,
    };
  },

  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
    /* tokensFields() {
      const arrayOfTokens = [];
      if (this.tokenModal?.lastField?.blueprintId) {
        const blueprint = this.blueprints.find(b => b._id.toString() === this.tokenModal?.lastField?.blueprintId.toString());
        const fieldBlueprint = (blueprint?.fields ?? []).map(item => {
          return { blueprintName: blueprint.name, ...item };
        });
        arrayOfTokens.push({ name: blueprint.name, fields: fieldBlueprint });
      }
      if (this.tokenModal?.instancesBlueprintId) {
        const mainBlueprint = this.blueprints.find(b => b._id.toString() === this.tokenModal?.instancesBlueprintId?.toString());
        const mainBlueprintFields = (mainBlueprint?.fields ?? []).map(item => {
          return { blueprintName: mainBlueprint.name, ...item };
        });
        arrayOfTokens.push({ name: mainBlueprint.name, fields: mainBlueprintFields });
      }
      return arrayOfTokens;
    }, */
    selectOptions() {
      return this.blueprints.map(b => {
        return {
          value: b._id,
          label: b.name,
        };
      });
    },
    instancesFromBlueprint() {
      const maiBlueprint = this.blueprints.find(item => item._id === this.selectedWorkflow.blueprint);
      const blueprintsMap = [{ value: maiBlueprint._id, label: `${maiBlueprint.name} (main)`, main: true }];
      this.selectedWorkflow?.events
        .filter((itemEvent, index) => index < this.eventIndex)
        .forEach(event => {
          if (event?.comparisons?.blueprint) {
            if (blueprintsMap.length === 1) {
              blueprintsMap.push({ value: '< Comparasion instances >', label: '< Comparasion instances >', disabled: true });
            }
            const blueprint = this.blueprints.find(item => item._id === event?.comparisons?.blueprint);
            if (blueprint._id !== maiBlueprint._id) {
              blueprintsMap.push({ value: blueprint._id, label: `${blueprint.name}` });
            } else {
              blueprintsMap.push({ value: `_${blueprintsMap.length}.${blueprint._id}`, label: `${blueprint.name}` });
            }
          }
          if (event?.pluginData?.pluginId) {
            const pluginData = event?.pluginData;
            blueprintsMap.push({ value: '< Plugin instances >', label: '< Plugin instances >', disabled: true });
            const plugin = this.plugins.find(item => item._id === pluginData.pluginId);
            blueprintsMap.push({
              value: pluginData.endPointId,
              label: `Plugin: ${plugin.title} -> ${plugin.endPoints.find(endPoint => endPoint._id === pluginData.endPointId)?.name ?? ''}`,
              type: 'plugin',
            });
          }
        });
      return blueprintsMap;
    },
  },

  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
    selectedBlueprintId() {
      this.fetchBlueprint();
    },
  },
  methods: {
    pluginExpectedFields(endPointId) {
      const endpoint = this.plugins.flatMap(plugin => plugin.endPoints).find(endPoint => endPoint._id === endPointId);
      // fields for select plugin
      if (endpoint) {
        return Object.keys(endpoint.expectedFields).map(key => ({ label: `${key} (${endpoint.expectedFields[key]})`, value: key }));
      }
      return [];
    },
    async fetchPlugins() {
      this.plugins = await pluginsManagementApi.getPluginsByApp();
    },
    getBpIdListTokens(fieldId, bpId) {
      const bpIdList = [];

      // adds the blueprint id of the last reference field in the chain
      const data = this.localEvent.copyData.find(item => item.blueprintId === bpId);
      if (data.fields) {
        const fields = data.fields.find(item => item.toFieldId === fieldId)?.fromFieldId ?? [];
        if (Array.isArray(fields)) {
          for (let i = fields.length - 1; i >= 0; i -= 1) {
            if (fields[i]?.type === 'reference') {
              const fl = this.blueprints
                .find(b => b._id.toString() === fields[i].blueprintId.toString())
                ?.fields?.find(f => f._id.toString() === fields[i].fieldId);
              bpIdList.push(fl.structure.ruleset.blueprintId);
              break;
            }
          }
        }
      }

      // adds event instance parent blueprint id
      bpIdList.push(this.selectedBlueprintId);

      return bpIdList;
    },
    /* openTokenModal(blueprintId, toFieldId, instancesBlueprintId) {
      this.tokenModal.visible = true;
      this.tokenModal.blueprintId = blueprintId;
      this.tokenModal.toFieldId = toFieldId;
      this.tokenModal.lastField = this.lastCopyFieldValue(blueprintId, toFieldId);
      const modalName = this.showRefFieldPath(blueprintId, toFieldId, false);
      this.tokenModal.modalName = modalName === '' ? this.blueprint.name : modalName;
      this.tokenModal.instancesBlueprintId = instancesBlueprintId;
    },
    closeTokenModal() {
      this.tokenModal.visible = false;
      this.tokenModal.blueprintId = null;
      this.tokenModal.toFieldId = null;
      this.tokenModal.lastField = null;
      this.tokenModal.modalName = '';
    },
    copyURL(field, base = '') {
      navigator.clipboard.writeText(this.generateToken(field, base));
    }, */
    generateToken(field, base = '') {
      return `{{=token${base !== '' ? `?.${slugify(base)}` : ''}?.${slugify(field.label)}}}`;
    },
    capitalize(value) {
      if (!value) return '';
      return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
    },
    editBindField(blueprintId, toFieldId) {
      if (this.bindField[blueprintId] === toFieldId) {
        this.bindField = { ...this.bindField, [blueprintId]: null };
      } else {
        this.bindField = { ...this.bindField, [blueprintId]: toFieldId };
      }
    },
    showRefFieldPath(blueprintId, toFieldId, addLastField = true) {
      const data = [...(this.localEvent.copyData.find(item => item.blueprintId === blueprintId)?.fields ?? [])].find(
        item => item.toFieldId === toFieldId
      )?.fromFieldId;
      const fields = [...(data ?? [])];
      const lastField = fields[fields.length - 1];
      if (!addLastField && lastField?.type !== 'reference') {
        fields.pop();
      }
      return fields
        .map(
          item =>
            `${item.fieldName.startsWith('_') ? _.startCase(item.fieldName.replace('_', '')).toUpperCase() : item.fieldName} (${item.fieldName === '_changedData' ? 'history' : item.type})`
        )
        .join(' > ');
    },
    findMainBlueprintReferenceFields() {
      Object.values(this.fields).forEach(field => {
        if ((field?.structure?.elementStructure?.type ?? field?.structure?.type) === 'reference') {
          const refBlueprintId = field?.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;
          const reBlueprint = this.blueprints.find(item => item._id.toString() === refBlueprintId.toString());

          this.mainBlueprintReferenceFields = {
            ...this.mainBlueprintReferenceFields,
            [field._id]: reBlueprint?.fields ?? [],
          };
        }
      });
    },
    selectBindType(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.toType ?? 'from-field';
      }
      return 'from-field';
    },
    getUseHtml(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.useToHtml ?? false;
      }
      return false;
    },
    setUseHtml(blueprintId, toFieldId, event) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        if (field) {
          field.useToHtml = event.target.checked;
        } else {
          data.fields = [...data.fields, { toFieldId, useToHtml: event.target.checked }];
        }
      }
    },
    fromPlugin(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.usePlugin ?? false;
      }
      return false;
    },
    fromHtml(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.fromHtml ?? '';
      }
      return '';
    },
    setFromHtml(blueprintId, toFieldId, value) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        field.fromHtml = value;
      }
    },
    staticValue(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.toValue ?? '';
      }
      return '';
    },
    /* lastCopyFieldValue(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data.fields) {
        const fields = data.fields.find(item => item.toFieldId === toFieldId)?.fromFieldId ?? [];
        let lastField = fields[fields.length - 1];
        if (lastField?.type === 'reference') {
          const fl = this.blueprints
            .find(b => b._id.toString() === lastField.blueprintId.toString())
            ?.fields?.find(f => f._id.toString() === lastField.fieldId);
          lastField = { ...lastField, blueprintId: fl.structure.ruleset.blueprintId };
        }
        return lastField;
      }
      return null;
    }, */
    changeStaticValue(blueprintId, toFieldId, e) {
      const toType = { target: { value: 'static-value' } };
      const { value } = e.target;
      this.changeBindType(blueprintId, toFieldId, toType, value);
    },
    changeBindType(blueprintId, toFieldId, toFieldType, e, toValue = null) {
      const toType = e.target.value;

      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toFieldType,
              toType,
              toValue: toValue ?? '',
              toHtml: '',
              useToHtml: false,
              fromFieldId: [],
              fromPluginFieldId: null,
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(item => item.toFieldId === toFieldId);
        if (field) {
          // update
          findCopyData.fields = findCopyData.fields.map(item => {
            if (item.toFieldId === toFieldId) {
              return {
                toFieldId,
                toFieldType,
                toType,
                toValue: toValue ?? '',
                toHtml: '',
                useToHtml: false,
                fromFieldId: [],
                fromPluginFieldId: null,
              };
            }
            return item;
          });
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toFieldType,
            toType,
            toValue: toValue ?? '',
            toHtml: '',
            useToHtml: false,
            fromFieldId: [],
            fromPluginFieldId: null,
          });
        }
      }
    },
    getBlueprintWithFields(blueprintId) {
      return this.blueprints.find(b => b._id.toString() === blueprintId ?? this.selectedBlueprintId);
    },
    getInstancesBlueprintId(blueprintId) {
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (findCopyData?.instancesFromPlugin) {
        return findCopyData?.pluginEndpointId ?? this.selectedWorkflow.blueprint;
      }
      return findCopyData?.instancesFromBlueprintId ?? this.selectedWorkflow.blueprint;
    },
    setInstancesBlueprintId(blueprintId, fromBlueprintId) {
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (this.instancesFromBlueprint.find(item => item.value === fromBlueprintId)?.type === 'plugin') {
        findCopyData.instancesFromPlugin = true;
        findCopyData.pluginEndpointId = fromBlueprintId;
      } else {
        findCopyData.instancesFromPlugin = false;
        findCopyData.instancesFromBlueprintId = fromBlueprintId;
      }
      findCopyData.fields = [];
    },
    getDataFromBlueprintForReference(blueprintId, toFieldId) {
      const instaceBlueprintId = this.getDataFromBlueprint(blueprintId, toFieldId);
      if (instaceBlueprintId.startsWith('_')) {
        return instaceBlueprintId.split('.')[1];
      }
      return instaceBlueprintId;
    },
    getDataFromBlueprint(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        if (field?.blueprintIdFieldValue) {
          return field?.blueprintIdFieldValue;
        }
      }
      return this.selectedBlueprintId;
    },
    setDataFromBlueprint(blueprintId, toFieldId, event) {
      this.resetId = toFieldId;
      const blueprintIdFieldValue = event.target.value;
      let usePlugin = false;
      if (this.instancesFromBlueprint.find(item => item.value === blueprintIdFieldValue)?.type === 'plugin') {
        usePlugin = true;
      }
      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toHtml: '',
              useToHtml: false,
              fromFieldId: [],
              usePlugin,
              blueprintIdFieldValue,
              fromPluginFieldId: null,
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(item => item.toFieldId === toFieldId);
        if (field) {
          // update
          findCopyData.fields = findCopyData.fields.map(item => {
            if (item.toFieldId === toFieldId) {
              return {
                toFieldId,
                toHtml: '',
                useToHtml: false,
                fromFieldId: [],
                usePlugin,
                blueprintIdFieldValue,
                fromPluginFieldId: null,
              };
            }
            return item;
          });
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toHtml: '',
            useToHtml: false,
            fromFieldId: [],
            usePlugin,
            blueprintIdFieldValue,
            fromPluginFieldId: null,
          });
        }
      }
      setTimeout(() => {
        this.resetId = null;
      }, 20);
    },
    selectField(blueprintId, toFieldId, toFieldType, value, index) {
      this.bindField = { ...this.bindField, [blueprintId]: toFieldId };

      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toFieldType,
              toType: 'from-field',
              toValue: null,
              toHtml: '',
              useToHtml: false,
              fromFieldId: [{ ...value, index }],
              fromPluginFieldId: null,
              usePlugin: false,
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(item => item.toFieldId === toFieldId);
        if (field && field.fromFieldId) {
          // update
          const referenceFields = field.fromFieldId.filter(item => item.index < index);
          if (value?.fieldId) {
            referenceFields.push({ ...value, index });
          }
          field.fromFieldId = referenceFields.sort((a, b) => a.index - b.index);
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toFieldType,
            toType: 'from-field',
            toValue: null,
            toHtml: '',
            useToHtml: false,
            fromFieldId: [{ ...value, index }],
            fromPluginFieldId: null,
            usePlugin: false,
          });
        }
      }
    },
    getPluginField(blueprintId, toFieldId) {
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (findCopyData) {
        const field = findCopyData.fields.find(item => item.toFieldId === toFieldId);
        if (field?.fromPluginFieldId) {
          return field?.fromPluginFieldId;
        }
      }
      return null;
    },
    setPluginField(blueprintId, toFieldId, toFieldType, value) {
      this.bindField = { ...this.bindField, [blueprintId]: toFieldId };

      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toFieldType,
              toType: 'from-field',
              toValue: null,
              toHtml: '',
              useToHtml: false,
              fromFieldId: [],
              fromPluginFieldId: value,
              usePlugin: true,
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(item => item.toFieldId === toFieldId);
        if (field && field.usePlugin) {
          // update
          field.fromPluginFieldId = value;
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toFieldType,
            toType: 'from-field',
            toValue: null,
            toHtml: '',
            useToHtml: false,
            fromFieldId: [],
            fromPluginFieldId: value,
            usePlugin: true,
          });
        }
      }
    },

    removeOne(blueprintId) {
      const el = this;
      this.$confirm({
        title: 'Confirm',
        content: 'Sure you want to delete?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        async onOk() {
          el.copyFromBlueprint = el.copyFromBlueprint.filter(item => item._id !== blueprintId);
          el.localEvent.copyData = el.localEvent.copyData.filter(item => item.blueprintId !== blueprintId);
        },
      });
    },

    async fetchAllBlueprint() {
      this.loadingBlueprints = true;
      this.blueprints = await blueprintApi.getAllWithFields();
      this.loadingBlueprints = false;
    },

    async selectedBlueprint(blueprintId) {
      if (!blueprintId) {
        this.selectedBlueprintValue = null;
        return;
      }
      const blueprint = this.blueprints.find(b => b._id.toString() === blueprintId.toString());
      if (!this.copyFromBlueprint.some(item => item._id === blueprint?._id)) {
        this.copyFromBlueprint.push({ ...blueprint });
        const { copyData } = this.localEvent;
        const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
        if (!findCopyData) {
          copyData.push({
            blueprintId,
            instancesFromBlueprintId: this.selectedBlueprintId.toString(),
            fields: [],
          });
        }
      }
      message.success({ content: 'Blueprint added!', duration: 2 });
      this.selectedBlueprintValue = null;
    },

    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const blueprint = this.blueprints.find(b => b._id.toString() === this.selectedBlueprintId.toString());
      this.fields = blueprint.fields;
      this.blueprint = blueprint;
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    resetReferenceField(fieldId) {
      if (this.resetId === fieldId) {
        return null;
      }
      return fieldId;
    },
    getUpdateFindInstance(blueprintId) {
      return this.localEvent.copyData.find(item => item.blueprintId === blueprintId)?.updateFindInstance ?? false;
    },
    setUpdateFindInstance(blueprintId, event) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        data.updateFindInstance = event.target.checked;
      }
    },
    getAddOnlyInstanceNotExistSearchFields(blueprintId) {
      return this.localEvent.copyData.find(item => item.blueprintId === blueprintId)?.addOnlyInstanceNotExistSearchFields ?? [];
    },
    setAddOnlyInstanceNotExistSearchFields(blueprintId, value) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        data.addOnlyInstanceNotExistSearchFields = value;
      }
    },
    getCheckedAddOnlyInstanceNotExist(blueprintId) {
      return this.localEvent.copyData.find(item => item.blueprintId === blueprintId)?.addOnlyInstanceNotExist ?? false;
    },
    setCheckedAddOnlyInstanceNotExist(blueprintId, event) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        data.addOnlyInstanceNotExist = event.target.checked;
      }
    },
  },
};
</script>
<style scoped>
.update-fields {
  display: table;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: 100%;
}
.update-fields:last-child {
  margin-bottom: 0px;
}
.update-fields .cell {
  display: table-cell;
  vertical-align: middle;
}
.input-name {
  width: 30%;
  border-right: 1px solid #ddd;
  height: 30px;
}
.modify-filed {
  width: 30%;
  padding-left: 10px;
}
.input-field {
  width: 40%;
  border-left: 1px solid #ddd;
  padding-left: 10px;
}
.mutted {
  font-size: 12px;
}
</style>
