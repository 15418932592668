<template>
  <div v-if="inputComponent">
    <div style="display: flex">
      <a-tooltip v-if="isInputTypeReference() && canUserCreate" :title="`Adauga ${field.label}`" class="mr-2">
        <a-button type="primary" size="small" @click="modalQuickAddVisible = true"><PlusOutlined /></a-button>
      </a-tooltip>
      <a-modal
        v-if="isInputTypeReference()"
        :footer="false"
        :width="'1200px'"
        :destroyOnClose="true"
        :maskClosable="false"
        :open="modalQuickAddVisible"
        :title="'Add new'"
        @cancel="modalQuickAddVisible = false"
      >
        <QuickCreate :blueprintId="referencedBlueprintId()" :addNew="true" @closePopup="reloadFieldValuesAndSetValue" />
      </a-modal>

      <label v-if="isLabelVisible" :class="labelCssClass">
        {{ field.label && field.label.trim() !== '' ? field.label : 'Untitled' }}
        <span v-if="field.isRequired" style="margin-left: 5px">*</span>
      </label>
    </div>

    <p class="description_above" v-if="isDescriptionVisible && widgetOptions.descriptionPlacement === 'above'">
      {{ field.description }}
    </p>
    <component
      :is="inputComponent"
      :indexGroups="indexGroups"
      :indexValue="groupIndexValue"
      :value="simpleValueOrArray"
      :field="field"
      ref="fieldComponent"
      :readOnly="!userRolesWhichCanCreate ? false : !userRolesWhichCanCreate.length"
      :structureRuleset="structureRuleset"
      :structureOptions="structureOptions"
      :widgetRuleset="widgetRuleset"
      :widgetOptions="widgetOptions"
      :inputRuleset="inputRuleset"
      :inputOptions="inputOptions"
      :inputSize="inputSize"
      :inputPlaceholder="inputPlaceholder"
      :autoFocus="autoFocus"
      :openDropdown="openDropdown"
      :waitForValidity="waitForValidity"
      :viewingMode="viewingMode"
      :rawValue="rawValue"
      :hideLabel="hideLabel"
      :arrowControl="arrowControl"
      @update="
        updatedValue => {
          emitInput(updatedValue);
        }
      "
      :currentFieldValues="currentFieldValues"
      :fields="fields"
      :addNew="addNew"
      :resetFieldValueOnHide="resetFieldValueOnHide"
    />
    <template v-if="validityFieldIndex === -1">
      <a-tag
        v-if="
          showIsInvalidMessage // allow show message  fields not in group
        "
        style="margin-top: 5px; display: block"
        color="red"
      >
        {{ field.logic.validation.failureMessage }}
      </a-tag>
    </template>
    <template v-if="validityFieldIndex?.length">
      <a-tag
        v-if="
          showIsInvalidMessage && // validation message gropus
          ((validityFieldIndex ? validityFieldIndex?.indexOf(groupIndexValue ?? -1) !== -1 : false) || !indexGroups) // allow show message on group
        "
        style="margin-top: 5px; display: block"
        color="red"
      >
        {{ field.logic.validation.failureMessage }}
      </a-tag>
    </template>
    <template v-if="showAcceptanceFailureMessages">
      <a-tag
        v-for="(failureMessage, index) in validity.acceptance.failureMessageList"
        :key="index"
        style="margin-top: 5px; display: block"
        color="red"
      >
        {{ failureMessage }}
      </a-tag>
    </template>
    <p class="description_below" v-if="isDescriptionVisible && widgetOptions.descriptionPlacement === 'below'">
      {{ field.description }}
    </p>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import { PlusOutlined } from '@ant-design/icons-vue';

import InputText from './Inputs/InputText.vue';
import InputNumber from './Inputs/InputNumber.vue';
import InputCheckbox from './Inputs/InputCheckbox.vue';
import InputCheckboxList from './Inputs/InputCheckboxList.vue';
import InputRadioGroup from './Inputs/InputRadioGroup.vue';
import InputSelect from './Inputs/InputSelect.vue';
import InputReference from './Inputs/InputReference.vue';
import InputArray from './Inputs/InputArray.vue';
import InputSelectMultiple from './Inputs/InputSelectMultiple.vue';
import InputDate from './Inputs/InputDate.vue';
import InputTime from './Inputs/InputTime.vue';
import InputFile from './Inputs/InputFile.vue';
import InputEditor from './Inputs/InputEditor.vue';
import SimpleText from './Inputs/SimpleText.vue';
import InputPhone from './Inputs/InputPhone.vue';
import InputUser from './Inputs/InputUser.vue';

const inputComponentsByType = {
  input_text: InputText,
  input_number: InputNumber,
  input_checkbox: InputCheckbox,
  input_radio_group: InputRadioGroup,
  input_select: InputSelect,
  input_reference: InputReference,
  input_array: InputArray,
  input_checkbox_list: InputCheckboxList,
  input_select_multiple: InputSelectMultiple,
  input_date: InputDate,
  input_time: InputTime,
  input_file: InputFile,
  input_editor: InputEditor,
  simple_text: SimpleText,
  input_phone: InputPhone,
  input_user: InputUser,
};

const QuickCreate = defineAsyncComponent(() => import('@dataSystem/components/InstanceCreateOrEdit/QuickCreate.vue'));

export default {
  name: 'field-widget',
  props: [
    'indexGroups',
    'indexValue',
    'rawValue',
    'modelValue',
    'field',
    'autoFocus',
    'hideLabel',
    'hideDescription',
    'openDropdown',
    'validity',
    'currentFieldValues',
    'fields',
    'userRolesWhichCanCreate',
    'userRoles',
    'waitForValidity',
    'addNew',
    'resetFieldValueOnHide',
    'viewingMode',
    'fieldIdToValidationFailedArray',
    'forceInputSize',
    'arrowControl',
  ],
  emits: ['update:modelValue', 'pressEnter', 'groupFieldValue', 'focus'],
  components: {
    QuickCreate,
    PlusOutlined,
  },
  data() {
    return {
      showIsInvalidMessage: false,
      showAcceptanceFailureMessages: false,
      validityFieldIndex: [],
      modalQuickAddVisible: false,
    };
  },
  created() {
    this.checkValidity();
  },
  computed: {
    simpleValueOrArray() {
      if (Array.isArray(this.modelValue)) {
        if (this.modelValue.length === 0) {
          return null;
        }
        return this.modelValue;
      }
      return this.modelValue;
    },
    inputComponent() {
      return inputComponentsByType[this.field.input.type] || null;
    },
    canUserCreate() {
      let canCreate = false;
      if (this.isInputTypeReference() && this.userRoles && this.userRoles.length) {
        this.userRoles.forEach(role => {
          if (role.permissionByBlueprintId[this.referencedBlueprintId()]?.createAuthority?.mode === 'yes') {
            canCreate = true;
          }
        });
      }
      return canCreate;
    },
    groupIndexValue() {
      return parseInt(this.indexValue ?? 0, 10);
    },
    isLabelVisible() {
      if (this.hideLabel) {
        return false;
      }
      if (!this.field.widget.ruleset.useLabel) {
        return false;
      }
      return this.field.widget.options.isLabelVisible;
    },
    isDescriptionVisible() {
      return (
        !this.hideDescription && this.widgetRuleset.useDescription && this.widgetOptions.isDescriptionVisible && this.field.description.trim() !== ''
      );
    },
    structureRuleset() {
      return this.field && this.field.structure && this.field.structure.ruleset ? this.field.structure.ruleset : null;
    },
    structureOptions() {
      return this.field && this.field.structure && this.field.structure.options ? this.field.structure.options : null;
    },
    widgetRuleset() {
      return this.field && this.field.widget && this.field.widget.ruleset ? this.field.widget.ruleset : null;
    },
    widgetOptions() {
      return this.field && this.field.widget && this.field.widget.options ? this.field.widget.options : null;
    },
    inputRuleset() {
      return this.field && this.field.input && this.field.input.ruleset ? this.field.input.ruleset : null;
    },
    inputOptions() {
      return this.field && this.field.input && this.field.input.options ? this.field.input.options : null;
    },
    inputSize() {
      if (this.forceInputSize) {
        return this.forceInputSize;
      }
      return this.inputRuleset.useSize && this.inputOptions.size ? this.inputOptions.size : 'default';
    },
    inputPlaceholder() {
      return this.inputRuleset.usePlaceholder && this.inputOptions.placeholder ? this.inputOptions.placeholder : null;
    },
    labelCssClass() {
      const cssClasses = {};

      if (this.field.widget.options.labelPlacement === 'left') {
        cssClasses.labelLeftAligned = true;
      } else {
        cssClasses.labelTopAligned = true;
      }

      if (this.field.widget.options.labelFontWeight === 'bold') {
        cssClasses.labelBold = true;
      }

      return cssClasses;
    },
  },
  watch: {
    validity: {
      deep: true,
      handler() {
        this.checkValidity();
      },
    },
  },
  methods: {
    reloadFieldValuesAndSetValue(instanceId) {
      if (this.isInputTypeReference()) {
        this.$refs.fieldComponent.setValueAndFetchInstances(instanceId);
      }
      this.modalQuickAddVisible = false;
    },
    emitInput(inputValue) {
      this.$emit('update:modelValue', inputValue);
    },
    checkValidity() {
      if (this.validity) {
        if (this.validity.passedValidation != null) {
          this.showIsInvalidMessage = !this.validity.passedValidation;
          if (this.showIsInvalidMessage) {
            this.validityFieldIndex = this.validity.validationFieldArrayIndex;
          } else {
            this.validityFieldIndex = [];
          }
        }
        if (this.validity.acceptance != null) {
          this.showAcceptanceFailureMessages = !this.validity.acceptance.isPassed;
        }
      }
    },
    isInputTypeReference() {
      return this.field.structure.type === 'reference' || this.field.structure?.elementStructure?.type === 'reference';
    },
    referencedBlueprintId() {
      if (this.isInputTypeReference()) {
        return this.field.structure.type === 'reference'
          ? this.field.structure.ruleset.blueprintId
          : this.field.structure.elementStructure.ruleset.blueprintId;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.labelTopAligned {
  display: block;
  margin-bottom: 5px;
}
.labelLeftAligned {
  display: inline-block;
  margin-right: 15px;
}
.labelBold {
  font-weight: bold;
}
.description_above {
  margin-bottom: 5px;
  margin-left: 5px;
  font-size: 12px;
}
.description_below {
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 12px;
}
</style>
