<template>
  <a-spin v-if="loading" class="text-center d-block p-3" />
  <template v-else>
    <div class="mb-2">
      <a-select
        v-model:value="localEvent.pluginData.pluginId"
        allowClear
        show-search
        placeholder="Select Plugin"
        style="width: 50%"
        :filter-option="filterOption"
      >
        <template v-for="plugin in plugins" :key="plugin._id">
          <a-select-option :value="plugin._id" :label="plugin.title">
            {{ plugin.title }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div class="mb-2" v-if="selectedPlugin">
      <a-select
        v-model:value="localEvent.pluginData.endPointId"
        allowClear
        show-search
        placeholder="Select EndPoint"
        style="width: 50%"
        :filter-option="filterOption"
        @change="updateExpectedFields"
      >
        <template v-for="endPoint in selectedPlugin.endPoints" :key="endPoint._id">
          <a-select-option :value="endPoint._id" :label="endPoint.name">
            {{ endPoint?.name !== '' ? endPoint?.name : endPoint?.path }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div v-if="selectedEndPoint?.expectedFields">
      <Codemirror
        disabled
        :model-value="codemirrorDataToStringify"
        :options="{
          btabSize: 4,
          mode: 'text/html',
          theme: 'base16-dark',
          lineNumbers: true,
          line: true,
        }"
        :extensions="extensions"
      />
    </div>
  </template>
</template>

<script>
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { blueprintApi } from '@/apps/dataSystem/api';
import { pluginsManagementApi } from '@/apps/pluginsManagement/api';
import { roleApplicationApi } from '@/apps/roleManagement/api';
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

export default {
  name: 'workflowBuilderPluginData',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList', 'eventIndex', 'prevEvent'],
  components: {
    Codemirror,
  },
  data() {
    return {
      loading: false,
      extensions: null,
      roles: [],
      subtenants: [],
      localEvent: { ...this.event },
      allBlueprints: [],
      mainBlueprint: null,
      mainBlueprintFields: [],
      plugins: [],
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {
    selectedPlugin() {
      if (this.plugins) {
        return this.plugins.find(plugin => plugin._id === this.localEvent.pluginData.pluginId);
      }
      return null;
    },
    selectedEndPoint() {
      if (this.selectedPlugin?.endPoints) {
        return this.selectedPlugin.endPoints.find(plugin => plugin._id === this.localEvent.pluginData.endPointId);
      }
      return null;
    },
    codemirrorDataToStringify() {
      return JSON.stringify(this.selectedEndPoint?.expectedFields ?? {}, null, 2);
    },
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
  },
  async created() {
    this.loading = true;
    this.extensions = [javascript(), oneDark];
    await this.fetchPlugins();
    // await this.fetchBlueprint();
    // await this.fetchRoles();
    this.loading = false;

    // this.data = {
    //   ...(this.event?.datesForYear ?? {}),
    // };
  },
  methods: {
    updateExpectedFields() {
      this.localEvent.pluginData.expectedFields = this.selectedEndPoint?.expectedFields;
    },
    async fetchPlugins() {
      this.plugins = await pluginsManagementApi.getPluginsByApp();
    },
    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);

      await this.fetchAllBlueprint();

      this.mainBlueprint = blueprint;
      this.mainBlueprintFields = Object.values(fieldsById);
    },
    async fetchAllBlueprint() {
      this.allBlueprints = await blueprintApi.getAllWithFields();
    },
    async fetchRoles() {
      this.roles = await roleApplicationApi.getAllForApplication();
    },
    filterOption(input, option) {
      return option.children()[0].children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
