<template>
  <div>
    <div v-if="referencedBlueprint">
      <a-row>
        <a-col :span="24" style="padding: 5px">
          Display field text<br />
          <a-input v-model:value="input.displayFieldsTokens"></a-input>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24" class="mb-2" style="padding: 5px"
          ><a-button @click="addDisplayField"><PlusOutlined /> Add</a-button></a-col
        >
        <a-col v-for="(displayField, i) in input.displayFieldsId" :key="i" :span="12" style="padding: 5px">
          <a-card style="padding-bottom: 25px">
            <a-button @click="removeDisplayField(displayField)"><MinusOutlined /> </a-button>
            <a-row>
              <a-col :span="24">
                Nume token<br />
                <a-input v-model:value="displayField.tokenName" style="margin-bottom: 5px"></a-input>
              </a-col>

              <a-col :span="24">
                fieldId<br />
                <a-select v-model:value="displayField.fieldId" :options="fieldOptions" style="width: 100%" @change="updateBpId(i)"></a-select>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <a-spin><LoadingOutlined spin style="fontsize: 24px" /></a-spin>
    </div>
  </div>
</template>

<script>
import blueprintApi from '@dataSystem/api/blueprint.api';
import { slugify } from '@/core/utils/string-manipulation';
import { LoadingOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

export default {
  name: 'DisplayTextOptionsIndex',
  mixins: [FieldInputOptionsMixin],
  components: {
    LoadingOutlined,
    MinusOutlined,
    PlusOutlined,
  },
  data() {
    return {
      referencedBlueprint: null,
    };
  },
  async created() {
    await this.getReferencedBlueprintData();
  },
  computed: {
    fieldOptions() {
      const options = [];

      if (this.referencedBlueprint) {
        Object.keys(this.referencedBlueprint.fieldsById).forEach(field => {
          if (Object.prototype.hasOwnProperty.call(this.referencedBlueprint.fieldsById, field)) {
            options.push({
              value: this.referencedBlueprint.fieldsById[field].id,
              label: this.referencedBlueprint.fieldsById[field].label,
            });
          }
        });
      }
      return options;
    },
  },
  methods: {
    async getReferencedBlueprintData() {
      if (this.input.structure.type === 'reference') {
        this.referencedBlueprint = await blueprintApi.getOne(this.input.structure.ruleset.blueprintId);
      } else {
        this.referencedBlueprint = await blueprintApi.getOne(this.input.structure.elementStructure.ruleset.blueprintId);
      }
    },
    updateBpId(index) {
      if (this.input.displayFieldsId[index]) {
        const selectedField = this.referencedBlueprint.fieldsById[this.input.displayFieldsId[index].fieldId];
        if (selectedField && selectedField.structure.type === 'reference') {
          this.input.displayFieldsId[index].blueprintId = selectedField.structure.ruleset.blueprintId;
        } else {
          this.input.displayFieldsId[index].blueprintId = null;
        }
        this.input.displayFieldsId[index].tokenName = slugify(selectedField.label);
      }
    },
    removeDisplayField(field) {
      this.input.displayFieldsId = this.input.displayFieldsId.filter(f => f !== field);
    },
    addDisplayField() {
      if (!this.input.displayFieldsId || !(this.input.displayFieldsId instanceof Array)) {
        this.input.displayFieldsId = [];
      }

      this.input.displayFieldsId.push({
        blueprintId: null,
        tokenName: '',
        fieldId: null,
      });
    },
  },
};
</script>

<style scoped></style>
