<template>
  <div class="mt-3 padd-10">
    <h3>Plugins</h3>
    <!--+ (!menu.active ? ' inactive' : '')-->
    <div v-for="plugin in pluginsList" :key="plugin.path" :class="'saas_card mb-10 hoverable'">
      <div class="row-contents">
        <div class="group-text-container" @click="openPlugin(plugin.path)">
          <a data-cy="linkSubtenantSelect">
            <div class="group-text">
              <span class="title">{{ plugin?.title ?? '' }}</span>
              <span>{{ plugin?.description ?? '' }}</span>
            </div>
          </a>
        </div>
        <div class="metadata">
          <div class="controls">
            <!--            @click="openMenu(plugin.path)"-->
            <!--            <a-tooltip placement="top">-->
            <!--              <template #title>-->
            <!--                <span>Edit</span>-->
            <!--              </template>-->
            <!--              <a-button @click="editMenu(plugin._id)" class="mr-10"><EditOutlined /> </a-button>-->
            <!--            </a-tooltip>-->

            <!--            <a-popconfirm-->
            <!--              placement="topLeft"-->
            <!--              :title="'Are you sure you want to delete `' + menu.name + '`?'"-->
            <!--              ok-text="Yes"-->
            <!--              cancel-text="No"-->
            <!--              @confirm="removeOne(menu._id)"-->
            <!--            >-->
            <!--              <a-button :loading="deleteOneLoading === menu._id"><DeleteOutlined /> </a-button>-->
            <!--            </a-popconfirm>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PluginsGetters, PluginsActions } from '../../shared/pluginsManagement.store';

export default {
  name: 'PluginsList',
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;

    await PluginsActions.fetchPluginsList();

    this.loading = false;
  },
  computed: {
    pluginsList: PluginsGetters.getAll,
  },
  methods: {
    openPlugin(pluginPath) {
      this.$router.push({ name: 'PluginBuilder', params: { pluginPath } });
    },
  },
};
</script>
