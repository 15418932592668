<template>
  <div>
    <FieldWidget
      v-model="localModelValue"
      :validity="fieldIdToValidity[field._id]"
      :waitForValidity="waitForValidityById[field._id]"
      :forceInputSize="'small'"
      :hideLabel="true"
      :hideDescription="true"
      :userRolesWhichCanCreate="userRolesWhichCanCreate"
      :userRoles="userRoles"
      :resetFieldValueOnHide="blueprint?.resetFieldValuesOnHide"
      :field="field"
      :arrowControl="false"
    />
  </div>
</template>
<script>
import { FieldWidget } from '@/apps/dataSystem/components/FieldWidget';
import { NewTemplatesGetters } from '@/apps/templateDataSystem/shared/newTemplateDataSystem.store';
import { BlueprintFormLogicMixin } from '@/apps/dataSystem/mixins/BlueprintLogicMixins';

export default {
  name: 'ListTableEditField',
  props: ['modelValue', 'record', 'column', 'field', 'rowIndex', 'columnKey', 'userRolesWhichCanCreate'],
  emits: ['update:modelValue'],
  mixins: [BlueprintFormLogicMixin],
  components: {
    FieldWidget,
  },
  created() {
    if (this.record[this.field._id] === null) {
      this.$emit('update:modelValue', null);
    }
    this.localModelValue = this.record[this.field._id];
  },
  data() {
    return {
      localModelValue: null,
    };
  },
  watch: {
    localModelValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
  computed: {
    userRoles: NewTemplatesGetters.getRoles,
  },
};
</script>
