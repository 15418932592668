<template>
  <a-select
    style="width: 100%"
    @change="selectProvider"
    :value="selectedFieldMapper.providerId == null ? undefined : selectedFieldMapper.providerId"
    placeholder="Select provider output"
  >
    <a-select-option v-for="provider in providerList" :key="provider._id">
      {{ provider.name }}
    </a-select-option>
  </a-select>

  <div v-if="selectedFieldMapper.providerId" style="padding-top: 20px">
    <h4>Provider output fields</h4>
    <a-alert v-if="!outputProviderFields.length" :show-icon="false" message="No fields" banner />

    <transition-group name="list" tag="div">
      <drag
        v-for="dataProvider in outputProviderFields"
        :key="dataProvider._id"
        :data="dataProvider"
        @cut="remove(n)"
        :go-back="true"
        :disabled="!!isFieldSelected(dataProvider.originalId).length"
        :class="isFieldSelected(dataProvider.originalId).length ? 'disabled' : ''"
      >
        <div class="saas_card hoverable_drag mb-10 from-sourse">
          <div class="row-contents">
            <div class="group-text-container">
              <div class="group-text">
                <span class="title">
                  <MoreOutlined />
                  <MoreOutlined style="margin-left: -11px" />
                  {{ dataProvider.label }}
                </span>
              </div>
            </div>
            <div class="metadata">
              <div class="controls">
                {{ isFieldSelected(dataProvider.originalId).length }}
              </div>
            </div>
          </div>
        </div>
      </drag>
    </transition-group>

    <div class="mt-3" v-if="dataProv.dynamicData?.active || dataProv.type === 'manual'">
      <h4>
        Provider core fields:
        <a-tooltip :mouseLeaveDelay="0" style="font-size: 0.7em; position: relative; top: -4px; color: #0059ff">
          <template #title>
            Available fields:<br /><br />
            -
            <span v-if="dataProv.dynamicData?.active"
              ><strong>instance ID</strong>: only available if the dataflow had dynamic data in any of it's requests; represents the id of the the
              instance that had dynamic data added to the dataflow request(s);</span
            ><br />
            -
            <span v-if="dataProv.dynamicData?.active"
              ><strong>blueprint ID</strong>: only available if the dataflow had dynamic data in any of it's requests; represents the id of the
              instance's blueprint that had dynamic data added to the dataflow request(s);</span
            ><br />
            -
            <span v-if="dataProv.type === 'manual'"
              ><strong>user ID</strong>: only available if the dataflow's type is manual; represents the id of the user that initiated the
              dataflow;</span
            ><br />
            -
            <span v-if="dataProv.type === 'manual'"
              ><strong>subtenant ID</strong>: only available if the dataflow's type is manual; represents the id of the subtenant from which the
              action originated;</span
            >
          </template>
          <InfoCircleOutlined />
        </a-tooltip>
      </h4>

      <transition-group name="list2" tag="div">
        <drag
          v-for="dataProvider in providerSpecialFields"
          :key="dataProvider._id"
          :data="dataProvider"
          :disabled="!!isFieldSelectedSpecial(dataProvider._id).length"
          :class="isFieldSelectedSpecial(dataProvider._id).length ? 'disabled' : ''"
        >
          <div
            class="saas_card hoverable_drag mb-10 from-sourse"
            v-if="
              (['_instanceId', '_blueprintId', '_fileDownload'].includes(dataProvider._id) && dataProv.type === 'manual') ||
              ['_userId', '_subtenantId'].includes(dataProvider._id)
            "
          >
            <div class="row-contents">
              <div class="group-text-container">
                <div class="group-text">
                  <span class="title">
                    <MoreOutlined />
                    <MoreOutlined style="margin-left: -11px" />
                    {{ dataProvider.label }}
                  </span>
                </div>
              </div>
              <div class="metadata">
                <div class="controls">
                  {{ isFieldSelectedSpecial(dataProvider._id).length }}
                </div>
              </div>
            </div>
          </div>
        </drag>
      </transition-group>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import { InfoCircleOutlined, MoreOutlined } from '@ant-design/icons-vue';
import { Drag } from 'vue-easy-dnd';

export default {
  props: ['providerSpecialFields', 'fieldMapperId', 'selectedFieldMapper', 'fieldMapperList', 'dataProv', 'providerList', 'outputProviderFields'],
  emits: ['selectProvider'],
  data() {
    return {
      loading: true,
      providerId: null,
      localeFieldMapperList: _.cloneDeep(this.fieldMapperList),
    };
  },
  methods: {
    pushFieldMapper(data) {
      this.localeFieldMapperList.push(data);
      this.$emit('update:fieldMapperList', this.localeFieldMapperList);
    },
    pushFieldMapperMappedFields(data) {
      this.localeFieldMapperList[0].mappedFields.push(data);
      this.$emit('update:fieldMapperList', this.localeFieldMapperList);
    },
    isFieldSelected(dataProviderFieldId) {
      if (this.selectedFieldMapper.fieldMapper.length) {
        return this.selectedFieldMapper.fieldMapper.filter(d => {
          return d.boundFields.some(el => el.dataProviderFieldId === dataProviderFieldId);
        });
      }
      return [];
    },
    isFieldSelectedSpecial(dataProviderSpecialFieldId) {
      if (this.selectedFieldMapper.fieldMapper.length) {
        return this.selectedFieldMapper.fieldMapper.filter(d => {
          return d.boundSpecialFields.some(el => el.dataProviderFieldId === dataProviderSpecialFieldId);
        });
      }
      return [];
    },
    selectProvider(key) {
      this.$emit('selectProvider', key);
    },
  },
  components: {
    Drag,
    InfoCircleOutlined,
    MoreOutlined,
  },
};
</script>
