<template>
  <div>
    <a-input v-if="field?.inputType === 'string'"> </a-input>
    <a-input-number v-if="field?.inputType === 'number'"></a-input-number>
  </div>
</template>

<script>
export default {
  name: 'PluginFieldWidget',
  props: ['field'],
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
