<template>
  <div class="text-center pt-2 pb-2" v-if="loading">
    <a-spin />
  </div>
  <template v-else>
    <div class="mb-2">
      <a-select
        v-model:value="selectedBlueprint"
        @change="value => onchangeBlueprint(value)"
        allowClear
        show-search
        placeholder="Select Business Objects"
        style="width: 50%"
        :filter-option="filterOption"
      >
        <template v-for="other_blueprint in blueprints" :key="other_blueprint._id">
          <a-select-option :value="other_blueprint._id" :label="other_blueprint.name">
            {{ other_blueprint.name }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <FieldConditions
      v-if="localEvent.comparisons.blueprint && blueprint && localEvent?.comparisons?.conditionings[0]?.conditions"
      v-model="localEvent.comparisons.conditionings[0].conditions"
      :useFieldManager="true"
      :fieldConditionsBlueprint="mainBlueprint"
      :fieldConditions="localEvent.comparisons.conditionings[0].conditions ?? []"
      :blueprints="blueprints"
      :selectedBlueprint="blueprint"
      :toFieldFromOtherBlueprint="true"
      :conditionsAcceptFieldValue="true"
      :conditionsAcceptFieldValueWithDepth="true"
      :justReferenceAndIds="false"
      :justReferenceFields="false"
      :autoFieldConditions="true"
      :mainBlueprint="mainBlueprint"
      :mainSelectedBlueprint="blueprint"
      :secondBlueprint="comparisonsMainBlueprint"
      :cardView="true"
      :otherBlueprint="true"
      :marginTopFromParent="'20px'"
      :showConditionsNulls="true"
      :pluginsData="plugins"
    ></FieldConditions>
    <!--      @setFieldConditionsData ="setFieldConditionsData"-->
    <div v-if="!fields">
      <br />
      <a-alert message="No fields found." banner />
    </div>
  </template>
</template>

<script>
import { blueprintApi } from '@/apps/dataSystem/api';
import FieldConditions from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/fieldConditions.vue';
import { WorkflowActions } from '@/apps/workflow/shared/workflow.store';
import { pluginsManagementApi } from '@/apps/pluginsManagement/api';

export default {
  name: 'workflowBuilderComparisonIndex',
  components: {
    FieldConditions,
  },
  props: ['workflows', 'selectedWorkflow', 'eventList', 'event', 'eventIndex', 'prevEvent'],
  data() {
    return {
      loading: false,
      fields: [],
      fieldsById: {},
      blueprint: null,
      refreshFields: false,
      refreshFieldChangedType: false,
      referenceFields: [],
      referenceFieldsKey: 1,
      blueprints: null,
      selectedBlueprint: null,
      localEvent: { ...this.event },

      plugins: [],
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchBlueprint();
    await this.loadBlueprints();
    await this.fetchPlugins();
    if (!this.localEvent.comparisons.conditionings.length) {
      this.localEvent.comparisons.conditionings.push({
        blueprintId: this.selectedBlueprintId,
        conditions: [],
      });
    }
    this.selectedBlueprint = this.localEvent.comparisons.blueprint;
    this.loading = false;
  },
  computed: {
    comparisonsMainBlueprint() {
      return this.blueprints?.find(bp => bp._id.toString() === this.localEvent.comparisons.blueprint.toString()) ?? null;
    },
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
    mainBlueprint() {
      if (this.blueprints) {
        return this.blueprints.find(bp => bp._id.toString() === this.blueprint._id.toString());
      }
      return {};
    },
  },

  methods: {
    onchangeBlueprint(value) {
      this.localEvent.comparisons.blueprint = null;
      (this.localEvent?.comparisons?.conditionings ?? []).forEach(conditions => {
        (conditions?.conditions ?? []).forEach(group => {
          (group?.conditions ?? []).forEach(condition => {
            (condition ?? []).forEach(params => {
              // eslint-disable-next-line no-param-reassign
              params.referenceField = undefined;
            });
          });
        });
      });
      this.localEvent.comparisons.blueprint = value ?? undefined;
    },
    async loadBlueprints() {
      this.blueprints = await blueprintApi.getAllWithFields();
    },

    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    async fetchPlugins() {
      const plugins = await pluginsManagementApi.getPluginsByApp();
      this.selectedWorkflow?.events
        .filter((_, index) => index < this.eventIndex)
        .forEach(event => {
          if (event?.pluginData?.pluginId) {
            const pluginData = event?.pluginData;
            const plugin = plugins.find(p => p._id === pluginData.pluginId);
            const endPoint = plugin.endPoints.find(ep => ep._id === pluginData.endPointId);
            this.plugins.push({
              name: plugin.title,
              path: plugin.path,
              endpoint: endPoint?.name ?? '',
              fields: Object.keys(endPoint?.expectedFields ?? []).map(key => ({
                label: `${key} (${endPoint.expectedFields[key]})`,
                type: endPoint.expectedFields[key],
                value: key,
              })),
            });
          }
        });
    },

    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);
      this.blueprint = blueprint;
      this.fieldsById = fieldsById;
      this.fields = Object.values(fieldsById);
    },
  },
};
</script>
<style scoped></style>
<script setup lang="ts"></script>
