import { reactive } from 'vue';
import LogRocket from 'logrocket';
import { authApi } from '@userManagement/api';
import { socket } from '@/socket';
import { notification } from 'ant-design-vue';

import { setAxioxAuthorizationHeader } from './auth.helper';

const initialState = {
  user: null,
};

const state = reactive({ ...initialState });

const Getters = {
  getUser: () => {
    return state.user;
  },
};

const Mutations = {
  SET_USER: user => {
    state.user = user;
  },
  UNSET_USER: () => {
    state.user = null;
  },
};

const Actions = {
  async login(email, password) {
    const { user, accessToken, defaultUrl } = await authApi.postLogin(email, password);
    Mutations.SET_USER(user);
    setAxioxAuthorizationHeader(accessToken);
    LogRocket.identify(user._id, {
      name: `${user.firstName}, ${user.lastName}`,
      email: user.email,
    });

    // Session listener for one-session functionality
    await Actions.setupSessionInvalidationListener(user._id);

    return defaultUrl;
  },
  async silentLogin() {
    const { user, accessToken } = await authApi.putLoginRefresh();
    Mutations.SET_USER(user);
    setAxioxAuthorizationHeader(accessToken);
    LogRocket.identify(user._id, {
      name: `${user.firstName}, ${user.lastName}`,
      email: user.email,
    });

    // Session listener for one-session functionality
    await Actions.setupSessionInvalidationListener(user._id);

    return { accessToken };
  },
  async logout() {
    const user = Getters.getUser();
    if (socket) {
      // Remove existing listener for this user to avoid duplicates
      socket.off(`session_invalidated:${user._id}`);
    }

    await authApi.deleteLogout();
    Mutations.UNSET_USER();

    // Lazy import router (avoid circular dependency) and redirect to login
    // eslint-disable-next-line import/no-cycle
    const router = (await import('@/router')).default;
    await router.push({ name: 'LoginComponent' });
  },

  async setupSessionInvalidationListener(userId) {
    if (socket) {
      // Remove existing listener for this user to avoid duplicates
      socket.off(`session_invalidated:${userId}`);
      // Add the listener for session invalidation
      socket.on(`session_invalidated:${userId}`, () => {
        notification.warning({
          message: 'Session Ended',
          description: 'You have been logged out because your account was logged in elsewhere.',
          duration: 0,
        });
        Actions.logout();
      });
    }
  },
};

export const UserGetters = Getters;
export const UserMutations = Mutations;
export const UserActions = Actions;
