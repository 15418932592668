import { reactive } from 'vue';

import { handleNested } from '@/core/utils/array-manipulation';

import { fileMapperApi } from '../api'; // Updated to use fileMapperApi

const initialState = {
  fileMapperList: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getFileMapperList: () => {
    return state.fileMapperList;
  },
};

const Mutations = {
  SET_FILEMAPPER_LIST: fileMapper => {
    state.fileMapperList = fileMapper;
  },

  INSERT_FILEMAPPER: fileMapper => {
    state.fileMapperList.push(fileMapper);
  },
  EDIT_FILEMAPPER: (fileMapperId, updatedFileMapper) => {
    const updatedFileMapperList = handleNested(state.fileMapperList, { _id: fileMapperId }, { update: updatedFileMapper });
    state.fileMapperList = updatedFileMapperList;
  },
  DELETE_FILEMAPPER: fileMapperId => {
    const updatedFileMapperList = handleNested(state.fileMapperList, { _id: fileMapperId });
    state.fileMapperList = updatedFileMapperList;
  },
};

const Actions = {
  fetchFileMapperList: async () => {
    const fileMapperList = await fileMapperApi.getAll();
    Mutations.SET_FILEMAPPER_LIST(fileMapperList);
  },

  createOneFileMapper: async fileMapper => {
    const newFileMapper = await fileMapperApi.postOne(fileMapper);
    Mutations.INSERT_FILEMAPPER(newFileMapper);
  },
  editOneFileMapper: async (fileMapperId, fileMapperForm) => {
    const updatedFileMapper = await fileMapperApi.putOne(fileMapperId, fileMapperForm);
    Mutations.EDIT_FILEMAPPER(fileMapperId, updatedFileMapper);
  },
  deleteOneFileMapper: async fileMapperId => {
    await fileMapperApi.deleteOne(fileMapperId);
    Mutations.DELETE_FILEMAPPER(fileMapperId);
  },

  createOneField: async (fileMapperId, fieldData) => {
    const updatedFileMapper = await fileMapperApi.postOneField(fileMapperId, fieldData);
    Mutations.EDIT_FILEMAPPER(fileMapperId, updatedFileMapper);
  },
  editOneField: async (fileMapperId, fieldId, fieldData) => {
    const updatedFileMapper = await fileMapperApi.putOneField(fileMapperId, fieldId, fieldData);
    Mutations.EDIT_FILEMAPPER(fileMapperId, updatedFileMapper);
  },
  deleteOneField: async (fileMapperId, fieldId) => {
    const updatedFileMapper = await fileMapperApi.deleteOneField(fileMapperId, fieldId);
    Mutations.EDIT_FILEMAPPER(fileMapperId, updatedFileMapper);
  },

  createOneBoundedField: async (fileMapperId, fieldId, boundedFieldData) => {
    const updatedFileMapper = await fileMapperApi.postOneBoundedField(fileMapperId, fieldId, boundedFieldData);
    Mutations.EDIT_FILEMAPPER(fileMapperId, updatedFileMapper);
  },
  deleteOneBoundedField: async (fileMapperId, fieldId, boundedFieldId) => {
    const updatedFileMapper = await fileMapperApi.deleteOneBoundedField(fileMapperId, fieldId, boundedFieldId);
    Mutations.EDIT_FILEMAPPER(fileMapperId, updatedFileMapper);
  },
  deleteBoundsByBlueprintId: async (fileMapperId, blueprintId) => {
    const updatedFileMapper = await fileMapperApi.deleteBoundsByBlueprintId(fileMapperId, blueprintId);
    Mutations.EDIT_FILEMAPPER(fileMapperId, updatedFileMapper);
  },
};

export const FileMapperGetters = Getters;
export const FileMapperMutations = Mutations;
export const FileMapperActions = Actions;
