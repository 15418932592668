<template>
  <div>
    <div v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
    <div v-else>
      <div class="saas_sections">
        <div class="sidebar">
          <div class="actions__timeline">
            <a-timeline>
              <a-timeline-item>
                <template #dot>
                  <AppstoreOutlined />
                </template>
                <p @click="deselectTenant" :class="tenantId == null ? 'strong' : ''" style="cursor: pointer">All tenants</p>
              </a-timeline-item>
              <a-timeline-item v-for="tenant in tenants" :key="tenant._id" :color="'green'" class="pointer">
                <template #dot>
                  <AppstoreOutlined />
                </template>
                <p @click="selectTenant(tenant.slug)">
                  <span
                    :class="tenant._id == tenantId && !appSlug ? 'strong' : ''"
                    :style="tenant._id == tenantId && appSlug ? 'font-weight: 500' : ''"
                  >
                    {{ tenant.name }}
                  </span>
                </p>
                <template v-if="tenant.applications">
                  <div class="applications" v-for="application in tenant.applications" :key="application._id">
                    <span
                      @click="selectApplication(tenant.slug, application.slug)"
                      :style="application._id == appId ? 'color:#000; font-weight: 700;' : ''"
                    >
                      {{ application.name }}
                    </span>
                  </div>
                </template>
              </a-timeline-item>

              <a-timeline-item
                v-if="user?.isSuperAdmin"
                @click="
                  openModal({
                    componentTarget: 'TASBlueprintForm',
                    componentName: 'Create Tenant',
                    edit: false,
                    editData: [],
                    type: 'tenant',
                  })
                "
                data-cy="btnTenantCreate"
              >
                <template #dot>
                  <PlusCircleOutlined />
                </template>
                <a>Create tenant</a>
              </a-timeline-item>
            </a-timeline>
          </div>
        </div>
        <div class="content-view">
          <div class="content-body">
            <ApplicationsList v-if="tenantId && !appId" :tenantId="tenantId" :appId="appId" :tenant="selectedTenant" @openModal="openModal" />
            <SubtenantsList
              v-else-if="tenantId && appId"
              :tenantId="tenantId"
              :appId="appId"
              :tenant="selectedTenant"
              :application="selectedAplication"
              @openModal="openModal"
            />
            <TenantsList v-else v-on:openModal="openModal" />
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:open="modal.visible" :footer="null" :destroyOnClose="true" width="700px">
      <template #title>{{ modal.componentName }}</template>
      <component
        :is="modal.component"
        :tenantId="tenantId"
        :appId="appId"
        :modal="modal"
        @closeModal="closeCreateModal"
        @modalUpdate="modalUpdate"
      ></component>
    </a-modal>
  </div>
</template>

<script>
import { TenantsActions, TenantsGetters } from '@tenants/shared/tenants.store';
import { SlugGetters } from '@/slug.store';
import { UserGetters } from '@userManagement/user.store';
import { AppstoreOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import TASBlueprintForm from './components/forms/TASBlueprintForm.vue';
import SubtenantsList from './components/SubtenantsList.vue';
import ApplicationsList from './components/ApplicationsList.vue';
import TenantsList from './components/TenantsList.vue';
// eslint-disable-next-line import/no-cycle

export default {
  data() {
    return {
      loading: false,
      visible: false,

      modal: {
        component: null,
        componentName: null,
        visible: false,

        edit: false,
        editData: [],
      },
    };
  },
  async created() {
    await TenantsActions.fetchTenants();
  },
  computed: {
    user: UserGetters.getUser,
    tenants: TenantsGetters.getTenants,
    tenantSlug: SlugGetters.getTenantSlug,
    appSlug: SlugGetters.getAppSlug,
    tenantId() {
      if (this.tenantSlug) {
        const tenant = this.tenants.find(t => t.slug === this.tenantSlug);
        return tenant ? tenant._id : null;
      }
      return null;
    },
    appId() {
      if (this.tenantSlug && this.appSlug) {
        const tenant = this.tenants.find(t => t.slug === this.tenantSlug);
        if (!tenant) {
          return null;
        }
        const application = tenant.applications.find(a => a.slug === this.appSlug);
        return application ? application._id : null;
      }
      return null;
    },

    selectedTenant() {
      return this.tenants.find(t => t._id === this.tenantId);
    },
    selectedAplication() {
      return this.selectedTenant ? this.selectedTenant.applications.find(a => a._id === this.appId) : [];
    },
  },
  methods: {
    modalUpdate(data) {
      this.modal = data;
    },
    selectTenant(tenantSlug) {
      if (tenantSlug !== this.tenantSlug || this.appSlug) {
        router.push({
          name: 'Tenant',
          params: { tenantSlug },
        });
      }
    },
    selectApplication(tenantSlug, appSlug) {
      if (appSlug !== this.appSlug) {
        router.push({
          name: 'TenantApplication',
          params: { tenantSlug, appSlug },
        });
      }
    },
    deselectTenant() {
      if (!this.tenantSlug) {
        return;
      }
      this.$router.push({
        name: 'TenantsList',
      });
    },
    openModal(objectParams) {
      this.modal = {
        component: objectParams?.componentTarget,
        componentName: objectParams?.componentName,
        visible: true,
        edit: objectParams?.edit ?? false,
        editData: objectParams?.editData ?? [],
        type: objectParams?.type ?? null,
      };
    },
    closeCreateModal() {
      this.modal.visible = false;
    },
  },
  components: {
    TenantsList,
    ApplicationsList,
    SubtenantsList,
    TASBlueprintForm,
    AppstoreOutlined,
    PlusCircleOutlined,
  },
};
</script>
<style scoped>
.applications {
  position: relative;
}
.applications::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 8px;
  display: block;
  background-color: #e8e8e8;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
