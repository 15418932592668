<template>
  <div>
    <h2>Blueprint destination</h2>
    <div>
      <label style="min-width: 125px; margin-right: 15px">Select a Blueprint</label>
      <BlueprintSelect v-model="selectedBlueprintId" placeholder="Select a blueprint..." style="width: 500px" />
      <a-button class="ml-3" @click="addNewBlueprint"><PlusOutlined /> Add Blueprint</a-button>
    </div>

    <div class="saas_card mb-10 mt-2" v-for="blueprint in blueprints" :key="blueprint._id">
      <div class="row-contents">
        <div class="group-text-container">
          <div class="group-text">
            <span class="title">
              <strong>
                {{ blueprint.name }}
              </strong>

              <span style="padding-left: 10px">fields</span>
            </span>
          </div>
        </div>
        <div class="metadata">
          <div class="controls">
            <a-popconfirm placement="topRight" ok-text="Yes" cancel-text="No" @confirm="removeBlueprintBounds(blueprint._id)">
              <template #title>
                <p>Are you sure you want to delete?</p>
              </template>
              <a-button><DeleteOutlined /></a-button>
            </a-popconfirm>
          </div>
        </div>
      </div>

      <div class="details-container">
        <div class="content">
          <div v-if="getBlueprintFields(blueprint._id).length">
            <template v-for="field in getBlueprintFields(blueprint._id)" :key="field._id">
              <drop
                v-if="field.structure.type !== 'reference'"
                class="saas_card mb-10"
                :accepts-data="() => !getField(blueprint._id, field._id)"
                @drop="onDropField(blueprint._id, field._id, $event)"
              >
                <div class="row-contents">
                  <div class="dataRow">
                    <div class="cell">
                      <div class="group-text-container group-text center">
                        <strong>
                          {{ field.label }}
                          <a-tag>Field ID: {{ field.successionIndex }}</a-tag>
                        </strong>
                      </div>
                    </div>
                    <div class="cell l-line" v-if="getField(blueprint._id, field._id)">
                      <SwapOutlined class="middle-icon" />
                      <div class="group-text-container saas_hoverable group-text" style="padding-left: 17px">
                        <strong>
                          {{ getField(blueprint._id, field._id).label }}
                        </strong>
                        <a-popconfirm
                          placement="topRight"
                          ok-text="Yes"
                          cancel-text="No"
                          @confirm="removeBindField(getField(blueprint._id, field._id))"
                        >
                          <template #title>
                            <p>Are you sure you want to delete?</p>
                          </template>
                          <a-button size="small" style="position: absolute; right: 6px"><DeleteOutlined /></a-button>
                        </a-popconfirm>
                      </div>
                    </div>
                  </div>
                </div>
              </drop>
              <div v-else class="saas_card mb-10">
                <div class="row-contents">
                  <div class="dataRow">
                    <div class="cell">
                      <div class="group-text-container group-text center">
                        <strong>
                          {{ field.label }}
                          <a-tag>Field ID: {{ field.successionIndex }}</a-tag>
                        </strong>
                      </div>
                    </div>
                    <div class="cell l-line">
                      <div class="group-text-container saas_hoverable group-text" style="padding-left: 17px">
                        Blueprint referenced.
                        <a-button
                          v-if="!blueprintsById[field.structure.ruleset.blueprintId]"
                          class="ml-3"
                          @click="addNewBlueprintById(field.structure.ruleset.blueprintId)"
                        >
                          <PlusOutlined /> Add Blueprint
                        </a-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="JSON.stringify(selectedFileMapper) !== '{}'">
      <h2>Embed Code</h2>
      <p>Click on the element below to copy the code and insert it in a template to add a button that triggers file import:</p>
      <a-tooltip :mouseLeaveDelay="0" title="Click to copy">
        <a-tag style="cursor: pointer" @click="copyEmbedCodeToClipboard">
          {{ embedString }}
        </a-tag>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import { Drop } from 'vue-easy-dnd';
import { notification } from 'ant-design-vue';

import { blueprintApi } from '@dataSystem/api';
import { BlueprintSelect } from '@dataSystem/components/BlueprintSelect';
import { FileMapperActions } from '@fieldMapper/shared/fileMapper.store';
import { DeleteOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons-vue';

export default {
  props: ['selectedFileMapper'],
  components: {
    Drop,
    BlueprintSelect,
    DeleteOutlined,
    PlusOutlined,
    SwapOutlined,
  },
  data() {
    return {
      loading: false,
      blueprintsById: {},
      destinations: [],
      selectedBlueprintId: null,
    };
  },
  async mounted() {
    this.loadBlueprints();
  },
  watch: {
    selectedFileMapper: {
      deep: true, // Ensures nested changes are also observed
      handler() {
        this.loadBlueprints();
      },
    },
  },
  computed: {
    embedString() {
      return `<InputFile type="primary" fileMapperId="${this.selectedFileMapper._id}" />`;
    },
    blueprints() {
      return Object.values(this.blueprintsById).sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    async loadBlueprints() {
      this.blueprintsById = {};
      this.destinations = [];

      if (JSON.stringify(this.selectedFileMapper) !== '{}') {
        const promises = this.selectedFileMapper.fields.flatMap(field =>
          field.boundedFields.map(async bind => {
            const { blueprint, fieldsById } = await blueprintApi.getOne(bind.blueprintId);

            this.blueprintsById[bind.blueprintId] = {
              ...blueprint,
              fields: Object.values(fieldsById),
            };

            this.destinations.push({
              label: field.name,
              fieldId: field._id,
              ...bind,
            });
          })
        );

        await Promise.all(promises);
      }
    },
    getBlueprintFields(blueprintId) {
      return this.blueprintsById[blueprintId] ? this.blueprintsById[blueprintId].fields : [];
    },
    async addNewBlueprint() {
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);

      this.blueprintsById[this.selectedBlueprintId] = {
        ...blueprint,
        fields: Object.values(fieldsById),
      };
    },
    async addNewBlueprintById(blueprintId) {
      const { blueprint, fieldsById } = await blueprintApi.getOne(blueprintId);

      this.blueprintsById[blueprintId] = {
        ...blueprint,
        fields: Object.values(fieldsById),
      };
    },
    getField(blueprintId, blueprintFieldId) {
      return this.destinations.find(d => d.blueprintId === blueprintId && d.blueprintFieldId === blueprintFieldId);
    },
    async onDropField(blueprintId, blueprintFieldId, e) {
      notification.success({
        message: 'Saved',
        description: `Field ${e.data.name} is linked.`,
      });

      this.destinations.push({
        label: e.data.name,
        fieldId: e.data._id,
        blueprintId,
        blueprintFieldId,
      });

      await FileMapperActions.createOneBoundedField(this.selectedFileMapper._id, e.data._id, { blueprintId, blueprintFieldId });
      this.$emit('updateFileMapperList');
    },
    async removeBindField(field) {
      notification.success({
        message: 'Saved',
        description: `Link of field ${field.label} has been removed.`,
      });
      this.destinations = this.destinations.filter(value => value._id !== field._id);
      await FileMapperActions.deleteOneBoundedField(this.selectedFileMapper._id, field.fieldId, field._id);
      this.$emit('updateFileMapperList');
    },
    async removeBlueprintBounds(blueprintId) {
      notification.success({
        message: 'Saved',
        description: `Blueprint ${this.blueprintsById[blueprintId].name} has been unbounded.`,
      });

      this.destinations = this.destinations.filter(value => value.blueprintId !== blueprintId);
      delete this.blueprintsById[blueprintId];

      await FileMapperActions.deleteBoundsByBlueprintId(this.selectedFileMapper._id, blueprintId);
      this.$emit('updateFileMapperList');
    },
    copyEmbedCodeToClipboard() {
      navigator.clipboard.writeText(this.embedString);

      notification.success({
        message: 'Saved',
        description: `Embed code copied to clipboard with success.`,
      });
    },
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  width: 50%;
  display: table-cell;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}

.middle-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2px 3px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.saas_hoverable:hover {
  background-color: rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
</style>
