<template>
  <div>
    <h2 style="margin: 0">{{ workflowName }}</h2>

    <div v-if="workflowDescription" class="muted-text mb-10">
      {{ workflowDescription }}
    </div>

    <div v-if="workflowId">
      <div class="saas_card" v-if="selectedWorkflow?.events" style="background-color: whitesmoke">
        <div class="row-contents">
          <div class="group-text-container">
            <div class="group-text">
              <span class="muted-text">{{ noTitleText }}</span>
              <span class="title">{{ selectedWorkflow?.name }}</span>
            </div>
          </div>
          <div class="metadata">
            <div class="controls">
              <a-button-group>
                <a-button type="dashed">
                  <strong>
                    {{ selectedWorkflowBlueprint ? selectedWorkflowBlueprint?.name : '...' }}
                  </strong>
                </a-button>
                <a-button v-if="selectedWorkflow?.selectedTrigger" type="primary">
                  {{ selectedWorkflow?.selectedTrigger }}
                </a-button>
              </a-button-group>
            </div>
          </div>
        </div>
        <EventsSettings
          v-if="!reloadSettings"
          :workflows="workflows"
          :selectedWorkflow="selectedWorkflow"
          @openEventId="value => (openEventId = value)"
          :workflowId="workflowId"
        ></EventsSettings>

        <EventsTriggers
          :workflows="workflows"
          :selectedWorkflow="selectedWorkflow"
          :selectedWorkflowBlueprint="selectedWorkflowBlueprint"
          @changedTrigger="resetSettings"
        ></EventsTriggers>
      </div>
      <div v-if="selectedWorkflow?.selectedTrigger" class="actions__line_arr">
        <a-button v-if="(selectedWorkflow?.events ?? []).length" type="primary" shape="circle" @click="newEvent([])" :loading="loadingEvent === 0">
          <template #icon>
            <PlusOutlined />
          </template>
        </a-button>
      </div>

      <div
        class="saas_card hoverable"
        v-if="
          selectedWorkflow?.selectedTrigger &&
          !(selectedWorkflow?.events ?? []).length &&
          (selectedWorkflow?.selectedTrigger !== 'TIMED' ||
            (selectedWorkflow?.selectedTrigger === 'TIMED' && (selectedWorkflow?.subtenantId ?? '').length > 0))
        "
        @click="newEvent([])"
      >
        <div class="row-contents">
          <div class="group-text-container">
            <div class="group-text">
              <span class="title">1. Do this ...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(event, key) in selectedWorkflow?.events ?? []" :key="event._id" :id="event._id" class="workflow-events">
      <div class="saas_card hoverable" :id="'event_' + event._id" :class="openEventId === event._id ? 'selected' : ''" @click="hideEditTitle($event)">
        <div class="row-contents">
          <div class="group-text-container" @click="expandEventBox(event._id)" @dblclick="editTitle(event._id)">
            <div class="group-text">
              <div v-if="editTitleId === event._id">
                <div class="edit-title">
                  <a-input
                    v-model:value="event.name"
                    :id="'input_' + event._id"
                    @pressEnter="hideEditTitle($event, true)"
                    :placeholder="event.name !== '' ? event.name : !key ? noTitleText : noTitleTextSecond"
                    allow-clear
                  />
                </div>
              </div>
              <div v-else>
                <div :class="{ 'muted-text': event.name !== '' }">
                  {{ key + 1 }}.
                  <span v-if="!key">{{ noTitleText }}</span>
                  <span v-else>{{ noTitleTextSecond }}</span>
                </div>
                <div v-if="event.name !== ''">{{ event.name }}</div>
              </div>
            </div>
          </div>
          <div class="metadata">
            <div class="controls">
              <a-button
                v-if="openEventId === event._id"
                @click="minimalizeEventBox(event._id)"
                :class="'minimalize'"
                style="margin-right: 12px"
                type="link"
              >
                <MinusSquareOutlined />
              </a-button>
              <a-popconfirm placement="topLeft" title="Are you sure delete this event?" ok-text="Yes" cancel-text="No" @confirm="removeEvent(event)">
                <a-button data-cy="delete"> <DeleteOutlined /> </a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
        <div class="details-container" v-if="openEventId === event._id || (openEventId && !openEventId)">
          <div class="content">
            <h3>Choose an Event</h3>
            <template v-for="eventList in eventsList" :key="eventList._id">
              <div class="saas_card hoverable mb-10" v-if="event.selectedEvent == null || eventList._id === event.selectedEvent">
                <div class="row-contents">
                  <div class="group-text-container" @click="openEvent(event, eventList)">
                    <div class="group-text">
                      <span class="title">
                        <component :is="eventList.icon"></component>
                        {{ eventList.name }}
                      </span>
                      <span class="muted-text">{{ eventList.details }}</span>
                    </div>
                  </div>
                  <div class="metadata" v-if="event.selectedEvent != null">
                    <div class="controls">
                      <a-button @click="closeEvent(event)" :class="'edit-btn'">
                        Edit
                        <template #icon>
                          <EditOutlined />
                        </template>
                      </a-button>
                    </div>
                  </div>
                </div>
                <div class="details-container" v-if="event.selectedEvent">
                  <div class="content">
                    <template v-for="eventList in eventsList" :key="eventList._id">
                      <component
                        v-if="eventList._id === event.selectedEvent"
                        :is="eventList.component"
                        :workflows="workflows"
                        :selectedWorkflow="selectedWorkflow"
                        :eventList="eventList"
                        :prevEvent="selectedWorkflow?.events[key - 1] ?? null"
                        :event="event"
                        :eventIndex="key"
                      ></component>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div
        v-if="event.selectedEvent || (selectedWorkflow?.events ?? []).length > key + 1"
        :class="(selectedWorkflow?.events ?? []).length === key + 1 ? 'actions__line' : 'actions__line_arr'"
      >
        <a-button data-cy="add-event" type="primary" shape="circle" @click="newEvent(event)" :loading="loadingEvent === key + 1">
          <template #icon>
            <PlusOutlined />
          </template>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BlueprintSelect } from '@dataSystem/components/BlueprintSelect';
import { blueprintApi } from '@dataSystem/api';

import {
  DeleteOutlined,
  EditOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  BellOutlined,
  MailOutlined,
  ApiOutlined,
  DisconnectOutlined,
  PullRequestOutlined,
  BranchesOutlined,
  FileProtectOutlined,
  CopyOutlined,
  UnlockOutlined,
  PoweroffOutlined,
  EuroCircleOutlined,
  ArrowDownOutlined,
  CalendarOutlined,
} from '@ant-design/icons-vue';
import { WorkflowActions, WorkflowGetters } from '@/apps/workflow/shared/workflow.store';
import EventsTriggers from '@/apps/workflow/views/WorkflowBuilder/components/workflowEvents/EventsTriggers.vue';
import EventsSettings from '@/apps/workflow/views/WorkflowBuilder/components/workflowEvents/EventsSettings.vue';
import NotificationEvent from '../events/Notification/index.vue';
import EmailEvent from '../events/Email/index.vue';
import ValidationEvent from '../events/Validation/index.vue';
import JumpEvent from '../events/Jump/index.vue';
import CatchFeedbackEvent from '../events/CatchFeedback/index.vue';
import UpdateFieldsEvent from '../events/UpdateFields/index.vue';
import CopyDataEvent from '../events/CopyData/index.vue';
import UpdatePermissionsEvent from '../events/UpdatePermissions/index.vue';
import EnableDisableDataProvider from '../events/EnableDisableDataProvider/index.vue';
import Exchange from '../events/Exchange/index.vue';
import ComparisonEvent from '../events/Comparison/index.vue';
import DataFlowEvent from '../events/DataFlow/index.vue';
import DatesForYearEvent from '../events/DatesForYear/index.vue';
import PluginData from '../events/PluginData/index.vue';

export default {
  props: ['workflowGroupId', 'workflowId', 'workflows', 'workflowName', 'workflowDescription', 'selectedEventId', 'eventsList'],
  name: 'WorkflowEvents',
  emits: ['open:event'],
  components: {
    EventsSettings,
    EventsTriggers,
    NotificationEvent,
    EmailEvent,
    ValidationEvent,
    ComparisonEvent,
    JumpEvent,
    CatchFeedbackEvent,
    UpdateFieldsEvent,
    CopyDataEvent,
    UpdatePermissionsEvent,
    EnableDisableDataProvider,
    BlueprintSelect,
    Exchange,
    DatesForYearEvent,
    DeleteOutlined,
    EditOutlined,
    MinusSquareOutlined,
    PlusOutlined,
    CalendarOutlined,
    BellOutlined,
    MailOutlined,
    ApiOutlined,
    DisconnectOutlined,
    PullRequestOutlined,
    BranchesOutlined,
    FileProtectOutlined,
    CopyOutlined,
    UnlockOutlined,
    PoweroffOutlined,
    EuroCircleOutlined,
    ArrowDownOutlined,
    DataFlowEvent,
    PluginData,
  },
  data() {
    return {
      loadingEvent: null,
      loadingActiveOutput: false,
      subtenantSlug: undefined,
      editTitleId: null,

      noTitleText: 'When this happens ...',
      noTitleTextSecond: 'Do this ...',

      selectedWorkflowBlueprint: null,

      openEventId: null,
      reloadSettings: false,
    };
  },
  async created() {
    this.openEventId = this.selectedEventId;
    this.selectedWorkflowBlueprint = await this.fetchBlueprint(this.selectedWorkflow?.blueprint);
  },
  watch: {
    selectedEventId(value) {
      this.openEventId = value;
    },

    selectedWorkflow: {
      deep: true,
      async handler(newSelected, oldSelected) {
        if (this.selectedWorkflow?.blueprint) {
          if (this.selectedWorkflowBlueprint === null || this.selectedWorkflowBlueprint._id !== this.selectedWorkflow.blueprint) {
            this.selectedWorkflowBlueprint = await this.fetchBlueprint(this.selectedWorkflow.blueprint);
          }
        }
        if (newSelected?._id !== oldSelected?._id) {
          this.resetSettings();
        }
      },
    },
  },
  computed: {
    selectedWorkflow() {
      return WorkflowGetters.getSelectedWorkflow(this.workflowId);
    },
  },
  methods: {
    async fetchBlueprint(blueprintId) {
      if (!blueprintId) {
        return null;
      }
      const { blueprint } = await blueprintApi.getOne(blueprintId);
      return blueprint;
    },
    expandEventBox(workflowId) {
      if (this.openEventId != null && workflowId === this.openEventId) {
        // this.openEventId = null;
        // this.$emit('open:event', null);
      } else {
        this.$emit('open:event', workflowId);
      }
    },
    minimalizeEventBox(workflowId) {
      if (this.openEventId !== null && workflowId === this.openEventId) {
        this.$emit('open:event', null);
      } else {
        // this.openEventId = workflowId;
        // this.$emit('open:event', workflowId);
      }
    },
    async openEvent(event, eventList) {
      event.selectedEvent = eventList._id;
    },
    async closeEvent(event) {
      event.selectedEvent = null;
      // reset events
      Object.entries(event).forEach(([key, value]) => {
        if (this.isPlainObject(value)) {
          this.resetEvent(key, value);
        }
      });
    },
    resetEvent(datakey, data) {
      Object.entries(data).forEach(([key, value]) => {
        if (key && value != null) {
          if (Array.isArray(value)) {
            // resetam array
            data[key] = [];
          } else if (this.isPlainObject(value)) {
            // resetam object
            this.resetEvent(key, value);
          } else {
            // resetam field
            data[key] = null;
          }
        }
      });
    },
    isPlainObject(value) {
      return value instanceof Object && Object.getPrototypeOf(value) === Object.prototype;
    },
    removeEvent(event) {
      WorkflowActions.removeOneEvent(this.workflowId, event._id);
    },
    editTitle(workflowId) {
      this.editTitleId = workflowId;
      setTimeout(function () {
        const el = document.getElementById(`input_${workflowId}`);
        if (el) {
          el.focus();
        }
      }, 400);
    },
    hideEditTitle(e, close = false) {
      if (!e.target.classList.contains('ant-input') || close) {
        this.editTitleId = null;
      }
    },
    //

    async newEvent(event) {
      const index = (this.selectedWorkflow?.events ?? []).indexOf(event);
      const position = index < 0 ? 0 : index + 1;
      this.loadingEvent = position;

      await WorkflowActions.addOneEvent(
        this.workflowId,
        {
          name: 'New event',
          idWorkflowGroup: this.workflowGroupId,
          idWorkflow: this.workflowId,
          index: position,
          subtenantId: [],
          filter: {
            conditionings: [],
            enabled: false,
          },
          recurence: {
            cronExpression: '0 0 * * *',
            type: 'noEnd',
            error: '',
          },
        },
        position
      );

      const workflow = this.workflows.find(item => item._id.toString() === this.workflowId.toString());
      const newEvent = workflow.events[position] ?? null;
      if (newEvent) {
        this.loadingEvent = null;
        this.expandEventBox(newEvent._id);
      }
    },
    resetSettings() {
      this.reloadSettings = true;
      setTimeout(() => {
        this.reloadSettings = false;
      }, 10);
    },
  },
};
</script>

<style scoped>
.edit-title {
  margin: 3px 0;
}
.minimalize {
  color: darkgray;
}

.moving-card {
  opacity: 0.4;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.sortable-ghost {
  opacity: 0.4;
}

.actions__line {
  text-align: center;
  padding: 15px 0 0 0;
  position: relative;
}
.actions__line::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  transform: translateX(-50%);
  top: 0;
  left: 50%;
  border-right: 2px solid #ccc;
  z-index: 0;
}
.actions__line_arr {
  text-align: center;
  padding: 15px 0 20px 0;
  position: relative;
}
.actions__line button {
  z-index: 1;
}

.actions__line_arr::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  transform: translateX(-50%);
  top: 0;
  left: 50%;
  border-right: 2px solid #ccc;
  z-index: 0;
}
.actions__line_arr::before {
  content: '';
  position: absolute;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #ccc transparent transparent transparent;
  z-index: 1;
}
.actions__line_arr button {
  z-index: 1;
}

.muted-text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.mb-10 {
  margin-bottom: 10px;
}
.saas-schedule-card {
  padding: 10px 20px;
}
</style>
