<template>
  <div class="notifications-container" style="display: inline !important">
    <a-badge @click="openNotificationsBox()" :offset="[0, 4]" :count="notSeen" style="margin-top: 5px; margin-right: 5px">
      <a-button type="link"><BellOutlined /></a-button>
    </a-badge>

    <div :class="'notifications' + (notificationBox ? ' open' : '')">
      <div class="header">
        <!-- <h3>{{ __lang('Notifications for %s and %s', ['alex', 'mihai']) }}</h3>-->
        <h3>{{ __lang('Notifications') }}</h3>
        <div class="right">
          <a-dropdown placement="bottomRight" :trigger="['click']">
            <a-tag :class="'cursor'"><MoreOutlined /></a-tag>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="seeAll">
                  <EyeOutlined />
                  See all
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item @click="clearAll">
                  <DeleteOutlined />
                  Clear all
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>

      <div class="no-notification" v-if="!notifications.length && !loading">
        {{ __lang('Nothing to show') }}
      </div>
      <ul @scroll="handleScroll">
        <li v-for="notification in notifications" :key="notification._id" :class="'notification' + (!notification.seen ? ' not-seen' : '')">
          <div class="title">{{ notification.title }}</div>
          <div class="description">{{ notification.message }}</div>
          <span class="time">
            <CalendarOutlined />
            {{ diffForHumans(notification.createdAt) }}
          </span>
          <a-tag v-if="notification.data && notification.data.values">
            {{ Object.values(notification.data.values).join(', ') }}
          </a-tag>

          <!-- TODO refactor this in a better way -->
          <div v-if="notification.continueWorkflowEvent">
            <template v-if="notification.continueWorkflowEvent.continueBy == 'either-one'">
              <div v-if="notification.continueWorkflowEvent.response.filter(n => n._id).length" style="padding-top: 5px">
                <a-tag color="#87d068" v-if="notification.continueWorkflowEvent.response[0].status"> Accepted </a-tag>
                <a-tag color="#108ee9" v-else>Declined</a-tag>
              </div>
              <div v-else>
                <div v-if="notification.continueWorkflowEvent.activ" style="margin-top: 10px">
                  <a-button type="primary" style="margin-right: 10px" @click="continueWorkflowEvent(notification, true)"> Accept </a-button>
                  <a-button @click="continueWorkflowEvent(notification, false)"> Decline </a-button>
                </div>
              </div>
            </template>

            <template v-else>
              <div v-if="notification.continueWorkflowEvent.response.some(n => n.owner == user._id)" style="padding-top: 5px">
                <a-tag color="#87d068" v-if="notification.continueWorkflowEvent.response.find(n => n.owner == user._id).status"> Accepted </a-tag>
                <a-tag color="#108ee9" v-else>Declined</a-tag>
              </div>
              <div v-else>
                <div v-if="notification.continueWorkflowEvent.activ" style="margin-top: 10px">
                  <a-button type="primary" style="margin-right: 10px" @click="continueWorkflowEvent(notification, true)"> Accept </a-button>
                  <a-button @click="continueWorkflowEvent(notification, false)"> Decline </a-button>
                </div>
              </div>
            </template>
            <!-- TODO refactor this in a better way -->
          </div>

          <div v-if="notification.condition && notification.condition.isEnabled">
            <template v-if="notification.condition.hasResponse">
              <a-tag color="#87d068" v-if="notification.condition.isAccepted"> Accepted </a-tag>
              <a-tag color="#108ee9" v-else>Declined</a-tag>
            </template>
            <template v-else>
              <a-button type="primary" style="margin-right: 10px" @click="() => onClickAcceptCondition(notification._id)"> Accept </a-button>
              <a-button @click="() => onClickDeclineCondition(notification._id)"> Decline </a-button>
            </template>
          </div>
        </li>
        <li class="loadMore" v-if="loadMore" @click="loadMyNotifications">
          <div style="padding-top: 10px">
            <a-spin size="small" />
            {{ __lang('loading...') }}
          </div>
        </li>
      </ul>
      <div class="footer">
        <a>{{ __lang('View all') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import io from 'socket.io-client';
import workflowApi from '@workflow/workflow.api';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { TenantsGetters } from '@tenants/shared/tenants.store';

import { SlugGetters } from '@/slug.store';
import { BellOutlined, CalendarOutlined, DeleteOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import notificationApi from '../../../notification.api';

export default {
  name: 'NotificationsComponent',
  components: {
    MoreOutlined,
    CalendarOutlined,
    DeleteOutlined,
    EyeOutlined,
    BellOutlined,
  },
  props: ['user'],
  data() {
    return {
      notSeen: 0,
      limit: 5,
      loading: false,
      loadMore: true,
      notificationBox: false,

      // socket: {},
      notifications: [],

      currentSubtenantId: null,
    };
  },
  beforeUnmount() {
    // if (process.env.VUE_APP_SOCKET_URL) {
    //   this.socket.off(`notification:${this.user._id}`);
    // }

    if (this.$socket) {
      this.$socket.off(`notification:${this.user._id}`);
    }
  },
  created() {
    dayjs.extend(relativeTime);

    // if (process.env.VUE_APP_SOCKET_URL) {
    //   this.socket = io(process.env.VUE_APP_SOCKET_URL);
    //   this.countNotSeenNotification();
    // }
  },
  computed: {
    notSeenIds() {
      return this.notifications.filter(item => !item.seen).map(item => item._id);
    },
  },
  mounted() {
    if (this.$socket) {
      this.$socket.on(`notification:${this.user._id}`, this.handleNotification);
      this.countNotSeenNotification();
    }
  },
  methods: {
    diffForHumans(date) {
      if (!date) {
        return null;
      }
      return dayjs(date).fromNow();
    },
    async clearAll() {
      this.notifications = await notificationApi.clearAll({
        notifications: this.notifications,
      });
    },
    handleNotification(data) {
      const subtenant = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());

      if (data.subtenantId === subtenant._id) {
        this.newNotification({
          new: true,
          ...data,
        });
      }
    },
    handleScroll(el) {
      if (this.loadMore && !this.loading) {
        const scrollHeight = el.srcElement.offsetHeight + Math.ceil(el.srcElement.scrollTop);
        const elemHeight = el.srcElement.scrollHeight;
        const offset = 100;

        if (scrollHeight - offset >= elemHeight - offset) {
          this.loadMyNotifications();
        }
      }
    },
    async openNotificationsBox() {
      if (this.notificationBox) {
        this.notificationBox = false;
      } else {
        this.notificationBox = true;

        if (!this.notifications.length || this.notifications.filter(n => n.new).length) {
          await this.loadMyNotifications();
        }
      }
    },

    async countNotSeenNotification() {
      try {
        const notSeen = await notificationApi.notSeen();
        this.notSeen += notSeen.notifications;
      } catch (error) {
        console.log(error);
      }
    },

    async loadMyNotifications() {
      if (!this.loading) {
        this.loading = true;
        try {
          const loadNotifications = await notificationApi.getMy({
            limit: this.limit,
            offset: this.notifications.length,
          });

          if (loadNotifications.length < this.limit) {
            this.loadMore = false;
          }

          const promises = Object.values(loadNotifications).map(async data => {
            this.notifications.push(data);
          });

          await Promise.all(promises);

          this.loading = false;
          await this.seeByIds();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async seeAll() {
      if (this.notSeen > 0) {
        this.notSeen = 0;
        await notificationApi.seeAll({
          notifications: this.notifications,
        });
        // this.notSeen -= seeAll.nModified;
        this.notifications = this.notifications.map(data => {
          return {
            ...data,
            seen: true,
          };
        });
      }
    },
    async seeByIds() {
      if (this.notSeen > 0) {
        const seeAll = await notificationApi.seeByIds({
          notifications: this.notSeenIds,
        });
        this.notSeen -= seeAll.nModified;

        this.notifications = this.notifications.map(data => {
          if (this.notSeenIds.indexOf(data._id) > -1) {
            return {
              ...data,
              seen: true,
            };
          }
          return data;
        });
      }
    },

    async newNotification(data) {
      let description = data.message;

      if (data.data && data.data.values) {
        description += ` - ${Object.values(data.data.values).join(', ')}`;
      }

      notification.open({
        message: data.title,
        description,
        icon: '<SmileOutline style={{color: "#108ee9"}} />',
      });
      this.notifications.unshift(data);
      this.notSeen += 1;
    },
    async continueWorkflowEvent(data, status) {
      const payload = {
        _id: data._id,
        to: data.to,
        continueWorkflowEvent: { ...data.continueWorkflowEvent },
        blueprint: data.data,
        status,
      };
      const updatedNotification = await workflowApi.continueWorkflowEvent(payload);
      const findUpdatedNotification = this.notifications.find(n => n._id === updatedNotification._id);
      if (findUpdatedNotification) {
        findUpdatedNotification.continueWorkflowEvent = updatedNotification.continueWorkflowEvent;
      }
    },

    async onClickAcceptCondition(notificationId) {
      const updatedNotification = await notificationApi.updateCondition(notificationId, {
        hasResponse: true,
        isAccepted: true,
      });
      const findUpdatedNotification = this.notifications.find(n => n._id === updatedNotification._id);
      if (findUpdatedNotification) {
        findUpdatedNotification.condition = updatedNotification.condition;
      }
    },

    async onClickDeclineCondition(notificationId) {
      const updatedNotification = await notificationApi.updateCondition(notificationId, {
        hasResponse: true,
        isAccepted: false,
      });
      const findUpdatedNotification = this.notifications.find(n => n._id === updatedNotification._id);
      if (findUpdatedNotification) {
        findUpdatedNotification.condition = updatedNotification.condition;
      }
    },
  },
};
</script>

<style scoped>
.notifications-container {
  position: relative;
}
.notifications {
  position: absolute;
  right: 0px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background-color: #ffffff;
  display: none;
  opacity: 0;
  transition: all 0.3s ease;
  height: calc(100vh - 50px);
}
.notifications.open {
  opacity: 1;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}
.notifications .header {
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px solid #d9d9d9;
}
.notifications .header .cursor {
  cursor: pointer;
}
.notifications .header h3 {
  margin: 0;
}
.notifications .header .right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.notifications ul {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  min-height: auto;
  height: 100%;
  overflow-y: auto;
  width: 300px;
}

.notifications ul li.notification {
  position: relative;
  list-style: none;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  padding: 10px 14px;
}
.notifications ul li.notification.not-seen::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: #1890ff;
}
.notifications ul li.notification:last-child {
  border-bottom: 0;
}

.notifications ul li:hover {
  background-color: #f3f3f3;
  transition: all 0.3s ease;
}
.notifications .footer {
  padding: 10px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.notification .title {
  font-weight: 600;
}
.notification .description {
  color: #3f3f3f;
}
.notification .time {
  font-size: 12px;
  font-weight: 600;
  display: block;
}

.not-seen .title,
.not-seen .time {
  color: #1890ff !important;
}

.loadMore {
  text-align: center;
  line-height: 30px;
}
.no-notification {
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .notifications {
    right: -50px !important;
  }
}
</style>
