// export function handleNested(
//   arrayData = [],
//   obj = {},
//   updateData = { delete: [] }
// ) {
//   const newArrayNested = [];
//   const type = Object.keys(updateData);
//   let key = Object.keys(obj);
//   let runRecursive = true;
//
//   if (obj._id) {
//     key = Object.keys(obj).filter(nestedKey => {
//       return typeof obj[nestedKey] === 'object';
//     });
//     if (!key.length) {
//       key = Object.keys(obj);
//     }
//   }
//   const findNested = Object.keys(obj[key]).filter(nestedKey => {
//     return typeof obj[key][nestedKey] === 'object';
//   });
//
//   arrayData.forEach(data => {
//     if (typeof obj[key] === 'object' && data[key]) {
//       if (obj._id) {
//         runRecursive = data._id === obj._id;
//       } else {
//         runRecursive = true;
//       }
//
//       if (findNested.length && runRecursive) {
//         newArrayNested.push({
//           ...data,
//           [key]: handleNested(
//             data[key],
//             { [findNested]: obj[key][findNested] },
//             updateData
//           ),
//         });
//       } else {
//         newArrayNested.push({
//           ...data,
//           [key]: handleNested(data[key], obj, updateData),
//         });
//       }
//     } else if (type === 'update') {
//       if (typeof obj[key] === 'string' && data._id === obj._id) {
//         newArrayNested.push(updateData[type]);
//       } else if (typeof obj[key] === 'object' && data._id === obj[key]._id) {
//         newArrayNested.push(updateData[type]);
//       } else {
//         newArrayNested.push(data);
//       }
//     } else if (typeof obj[key] === 'string' && data._id !== obj._id) {
//       newArrayNested.push(data);
//     } else if (typeof obj[key] === 'object' && data._id !== obj[key]._id) {
//       newArrayNested.push(data);
//     }
//   });
//   return newArrayNested;
// }

/**
 * Wrapper for controllers to run validators and extend Express' request and response
 * @param {Object[]} arrayData
 * @param {Object} obj
 * @param {{delete: *[]}} updateData
 * @param {Object[]} updateData.update
 * @param {Object[]} updateData.delete
 */
export function handleNested(arrayData = [], obj = {}, updateData = { delete: [] }) {
  const updatedArray = arrayData.map(data => {
    // Check if the current object matches the one to be updated
    if (data._id === obj._id) {
      // If `updateData` has an 'update' key, return the updated object
      if (updateData.update) {
        return { ...data, ...updateData.update };
      }
      // If `updateData` has a 'delete' key, return null (filter out this object later)
      if (updateData.delete) {
        return null;
      }
    }

    // If the object has nested arrays or objects, recursively update them
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        data[key] = handleNested(value, obj, updateData);
      } else if (typeof value === 'object' && value !== null) {
        const nestedResult = handleNested([value], obj, updateData);
        data[key] = nestedResult.length > 0 ? nestedResult[0] : value;
      }
    });

    // If no update or delete, return the original object
    return data;
  });

  // Filter out any `null` values which indicate deletion
  return updatedArray.filter(data => data !== null);
}

export function findInObjectKey(data, key = 'model') {
  if (data == null) {
    return [];
  }
  let item = [];
  if (!data) return item;
  if (data instanceof Array) {
    data.forEach(dataItem => {
      item = item.concat(findInObjectKey(dataItem, key));
    });
    return item;
  }

  if (typeof data[key] !== 'undefined') {
    return data[key];
  }

  if (typeof data === 'object') {
    const children = Object.keys(data);
    if (children.length > 0) {
      children.forEach(child => {
        item = item.concat(findInObjectKey(data[child], key));
      });
    }
  }
  return item;
}

/* export function findInObject(objects, id, key = "_id", nestingKey = "children", parentInstanceId) {
  for (let obj of objects || []) {
    if (obj[key] === id) {
      if (parentInstanceId) {
        if (obj.parentInstanceId === parentInstanceId) {
          return obj;
        }
      } else {
        return obj;
      }
    }

    const obj_ = findInObject(obj[nestingKey], id, key, nestingKey);
    if (obj_) return obj_;
  }
} */

/* export function findNode(treeNodes, nodeId, parentInstanceId) {
  for (const node of treeNodes || []) {
    if (node._id === nodeId) {
      if (parentInstanceId) {
        if (node.parentInstanceId === parentInstanceId) {
          return node;
        }
      } else {
        return node;
      }
    }

    const node_ = findNode(node.children, nodeId, parentInstanceId);
    if (node_) return node_;
  }
} */

export function findInObjectAncestors({ children = [], ...object }, id) {
  let result;
  if (object._id === id) return object;

  const found = children.some(o => {
    result = findInObjectAncestors(o, id);
    return result;
  });

  if (found) {
    return {
      ...object,
      children: [{ title: result.title, _id: result._id, children: result.children }],
    };
  }

  return null;
}

export function extractObjectId(str) {
  const match = str.match(/_([a-f0-9]{24})/);
  return match ? match[1] : null;
}

export function objectId() {
  return `${((new Date().getTime() / 1000) | 0).toString(16)}xxxxxxxxxxxxxxxx`
    .replace(/[x]/g, () => {
      return ((Math.random() * 16) | 0).toString(16);
    })
    .toLowerCase();
}
//

export const asyncFilter = async (arr, predicate) => {
  const results = await Promise.all(arr.map(predicate));
  return arr.filter((_v, index) => results[index]);
};

export const asyncFind = async (arr, predicate) => {
  const results = await Promise.all(arr.map(predicate));
  return arr.find((_v, index) => results[index]);
};

export const keyValueFromString = (propString, obj) => {
  const props = String(propString).split('.');

  const result = props.reduce((acc, prop) => {
    if (acc && Object.prototype.hasOwnProperty.call(acc, prop)) {
      return acc[prop];
    }
    return null;
  }, obj);

  return result !== null ? result : '';
};

/* export const asyncGroupByField = async (array, field) => {
  const grouped = {};
  for await (const obj of array) {
    const value = obj[field];
    if (value) {
      if (!grouped[value]) {
        grouped[value] = [];
      }
      grouped[value].push(obj);
    }
  }
  return grouped;
} */

export const findRecursive = (array, value, field = '_id') => {
  let result = null;

  array.forEach(obj => {
    if (result) return; // Early exit if result is already found
    if (obj[field] === value) {
      result = obj;
      return;
    }
    if (obj.children && obj.children.length > 0) {
      result = findRecursive(obj.children, value, field);
    }
  });

  return result;
};

export const findAndAddMoveRecursive = (array, value, newElem, position, moveToMenuId = null, field = '_id') => {
  const data = [];

  array.forEach(obj => {
    if (obj[field] === value || (obj[field] === moveToMenuId && moveToMenuId)) {
      if (!moveToMenuId || obj[field] === moveToMenuId) {
        if (position === 'after' && moveToMenuId) {
          data.push({ ...obj });
          data.push({ ...newElem });
        } else if (position === 'before' && moveToMenuId) {
          data.push({ ...newElem });
          data.push({ ...obj });
        } else if (position === 'as_child' && moveToMenuId) {
          data.push({ ...obj, children: [...obj.children, { ...newElem }] });
        } else {
          data.push({ ...newElem });
        }
      }
    } else {
      const newObj = { ...obj };
      if (obj.children && obj.children.length > 0) {
        newObj.children = findAndAddMoveRecursive(obj.children, value, newElem, position, moveToMenuId, field);
      }
      data.push(newObj);
    }
  });

  return data;
};

export const findAndRemoveRecursive = (array, value, field = '_id') => {
  const data = [];

  array.forEach(obj => {
    if (obj[field] !== value) {
      const newObj = { ...obj }; // Create a new object to avoid modifying the original
      if (obj.children && obj.children.length > 0) {
        newObj.children = findAndRemoveRecursive(obj.children, value, field); // Recursively process children
      }
      data.push(newObj);
    }
  });

  return data;
};

/* export const groupByField = (array, field) => {
  const grouped = {};
  for (const obj of array) {
    const value = obj[field];
    if (value) {
      if (!grouped[value]) {
        grouped[value] = [];
      }
      grouped[value].push(obj);
    }
  }
  return grouped;
} */

export const filterTypeHandlerMap = {
  EQUAL: (entryValue, expectedValue) => entryValue === expectedValue,
  NOT_EQUAL: (entryValue, expectedValue) => entryValue !== expectedValue,
  GREATER: (entryValue, expectedValue) => entryValue > expectedValue,
  GREATER_OR_EQUAL: (entryValue, expectedValue) => entryValue >= expectedValue,
  LOWER: (entryValue, expectedValue) => entryValue < expectedValue,
  LOWER_OR_EQUAL: (entryValue, expectedValue) => entryValue <= expectedValue,
  EXISTS: entryValue => entryValue !== undefined && entryValue !== null,
};
