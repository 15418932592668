<template>
  <div>
    <h2>Blueprint Sidebar</h2>
  </div>
</template>

<script>
export default {
  props: [],

  data() {
    return {
      loading: false,
    };
  },

  computed: {},
  methods: {},
};
</script>

<style></style>
