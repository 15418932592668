<template>
  <div v-if="!viewingMode">
    <a-date-picker
      :size="inputSize ?? ''"
      :format="fieldFormat"
      :valueFormat="fieldFormat"
      :disabled="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)"
      v-model:value="inputValue"
      style="width: 100%"
    />
  </div>

  <div v-else>
    <a-date-picker :format="fieldFormat" :valueFormat="fieldFormat" v-model:value="rawValue" :disabled="true" style="width: 100%" />
  </div>
</template>

<script>
import InputMixin from './InputMixin';
import 'dayjs/locale/ro';

export default {
  mixins: [InputMixin],
  emits: ['blur'],
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    fieldFormat() {
      return this.field.structure?.elementStructure?.options?.format ?? this.field.structure.options.format;
    },
  },
  created() {
    this.transformPropValue();
  },
};
</script>
